

.trigger_headers {

}
.Collapsible{
    margin-top: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: black;
    cursor: pointer;
}
.Collapsible__trigger{
    width: 100%;
    background-color: #1f8ded;
    height: 40px;
    color: white;
    font-size: 16px;
    font-weight: bolder;
    line-height: 40px;
    padding-left: 10px;

}
.Collapsible__contentInner{
    margin-top: 10px;
    padding-left: 10px;
}
