svg:not(:root).svg-inline--fa {
    overflow: visible;
}

.svg-inline--fa {
    display: inline-block;
    font-size: inherit;
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
}
.svg-inline--fa.fa-lg {
    vertical-align: -0.225em;
}
.svg-inline--fa.fa-w-1 {
    width: 0.0625em;
}
.svg-inline--fa.fa-w-2 {
    width: 0.125em;
}
.svg-inline--fa.fa-w-3 {
    width: 0.1875em;
}
.svg-inline--fa.fa-w-4 {
    width: 0.25em;
}
.svg-inline--fa.fa-w-5 {
    width: 0.3125em;
}
.svg-inline--fa.fa-w-6 {
    width: 0.375em;
}
.svg-inline--fa.fa-w-7 {
    width: 0.4375em;
}
.svg-inline--fa.fa-w-8 {
    width: 0.5em;
}
.svg-inline--fa.fa-w-9 {
    width: 0.5625em;
}
.svg-inline--fa.fa-w-10 {
    width: 0.625em;
}
.svg-inline--fa.fa-w-11 {
    width: 0.6875em;
}
.svg-inline--fa.fa-w-12 {
    width: 0.75em;
}
.svg-inline--fa.fa-w-13 {
    width: 0.8125em;
}
.svg-inline--fa.fa-w-14 {
    width: 0.875em;
}
.svg-inline--fa.fa-w-15 {
    width: 0.9375em;
}
.svg-inline--fa.fa-w-16 {
    width: 1em;
}
.svg-inline--fa.fa-w-17 {
    width: 1.0625em;
}
.svg-inline--fa.fa-w-18 {
    width: 1.125em;
}
.svg-inline--fa.fa-w-19 {
    width: 1.1875em;
}
.svg-inline--fa.fa-w-20 {
    width: 1.25em;
}
.svg-inline--fa.fa-pull-left {
    margin-right: 0.3em;
    width: auto;
}
.svg-inline--fa.fa-pull-right {
    margin-left: 0.3em;
    width: auto;
}
.svg-inline--fa.fa-border {
    height: 1.5em;
}
.svg-inline--fa.fa-li {
    width: 2em;
}
.svg-inline--fa.fa-fw {
    width: 1.25em;
}

.fa-layers svg.svg-inline--fa {
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
}

.fa-layers {
    display: inline-block;
    height: 1em;
    position: relative;
    text-align: center;
    vertical-align: -0.125em;
    width: 1em;
}
.fa-layers svg.svg-inline--fa {
    -webkit-transform-origin: center center;
    transform-origin: center center;
}

.fa-layers-counter, .fa-layers-text {
    display: inline-block;
    position: absolute;
    text-align: center;
}

.fa-layers-text {
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transform-origin: center center;
    transform-origin: center center;
}

.fa-layers-counter {
    background-color: #ff253a;
    border-radius: 1em;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #fff;
    height: 1.5em;
    line-height: 1;
    max-width: 5em;
    min-width: 1.5em;
    overflow: hidden;
    padding: 0.25em;
    right: 0;
    text-overflow: ellipsis;
    top: 0;
    -webkit-transform: scale(0.25);
    transform: scale(0.25);
    -webkit-transform-origin: top right;
    transform-origin: top right;
}

.fa-layers-bottom-right {
    bottom: 0;
    right: 0;
    top: auto;
    -webkit-transform: scale(0.25);
    transform: scale(0.25);
    -webkit-transform-origin: bottom right;
    transform-origin: bottom right;
}

.fa-layers-bottom-left {
    bottom: 0;
    left: 0;
    right: auto;
    top: auto;
    -webkit-transform: scale(0.25);
    transform: scale(0.25);
    -webkit-transform-origin: bottom left;
    transform-origin: bottom left;
}

.fa-layers-top-right {
    right: 0;
    top: 0;
    -webkit-transform: scale(0.25);
    transform: scale(0.25);
    -webkit-transform-origin: top right;
    transform-origin: top right;
}

.fa-layers-top-left {
    left: 0;
    right: auto;
    top: 0;
    -webkit-transform: scale(0.25);
    transform: scale(0.25);
    -webkit-transform-origin: top left;
    transform-origin: top left;
}

.fa-lg {
    font-size: 1.3333333333em;
    line-height: 0.75em;
    vertical-align: -0.0667em;
}

.fa-xs {
    font-size: 0.75em;
}

.fa-sm {
    font-size: 0.875em;
}

.fa-1x {
    font-size: 1em;
}

.fa-2x {
    font-size: 2em;
}

.fa-3x {
    font-size: 3em;
}

.fa-4x {
    font-size: 4em;
}

.fa-5x {
    font-size: 5em;
}

.fa-6x {
    font-size: 6em;
}

.fa-7x {
    font-size: 7em;
}

.fa-8x {
    font-size: 8em;
}

.fa-9x {
    font-size: 9em;
}

.fa-10x {
    font-size: 10em;
}

.fa-fw {
    text-align: center;
    width: 1.25em;
}

.fa-ul {
    list-style-type: none;
    margin-left: 2.5em;
    padding-left: 0;
}
.fa-ul > li {
    position: relative;
}

.fa-li {
    left: -2em;
    position: absolute;
    text-align: center;
    width: 2em;
    line-height: inherit;
}

.fa-border {
    border: solid 0.08em #eee;
    border-radius: 0.1em;
    padding: 0.2em 0.25em 0.15em;
}

.fa-pull-left {
    float: left;
}

.fa-pull-right {
    float: right;
}

.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
    margin-right: 0.3em;
}
.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
    margin-left: 0.3em;
}

.fa-spin {
    -webkit-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear;
}

.fa-pulse {
    -webkit-animation: fa-spin 1s infinite steps(8);
    animation: fa-spin 1s infinite steps(8);
}

@-webkit-keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.fa-rotate-90 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.fa-rotate-180 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.fa-rotate-270 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
}

.fa-flip-horizontal {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
    -webkit-transform: scale(-1, 1);
    transform: scale(-1, 1);
}

.fa-flip-vertical {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
    -webkit-transform: scale(1, -1);
    transform: scale(1, -1);
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
    -webkit-transform: scale(-1, -1);
    transform: scale(-1, -1);
}

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-flip-both {
    -webkit-filter: none;
    filter: none;
}

.fa-stack {
    display: inline-block;
    height: 2em;
    position: relative;
    width: 2.5em;
}

.fa-stack-1x,
.fa-stack-2x {
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
}

.svg-inline--fa.fa-stack-1x {
    height: 1em;
    width: 1.25em;
}
.svg-inline--fa.fa-stack-2x {
    height: 2em;
    width: 2.5em;
}

.fa-inverse {
    color: #fff;
}

.sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

.svg-inline--fa .fa-primary {
    fill: var(--fa-primary-color, currentColor);
    opacity: 1;
    opacity: var(--fa-primary-opacity, 1);
}

.svg-inline--fa .fa-secondary {
    fill: var(--fa-secondary-color, currentColor);
    opacity: 0.4;
    opacity: var(--fa-secondary-opacity, 0.4);
}

.svg-inline--fa.fa-swap-opacity .fa-primary {
    opacity: 0.4;
    opacity: var(--fa-secondary-opacity, 0.4);
}

.svg-inline--fa.fa-swap-opacity .fa-secondary {
    opacity: 1;
    opacity: var(--fa-primary-opacity, 1);
}

.svg-inline--fa mask .fa-primary,
.svg-inline--fa mask .fa-secondary {
    fill: black;
}

.fad.fa-inverse {
    color: #fff;
}

.apexcharts-canvas {
    position: relative;
    user-select: none;
    /* cannot give overflow: hidden as it will crop tooltips which overflow outside chart area */
}


/* scrollbar is not visible by default for legend, hence forcing the visibility */
.apexcharts-canvas ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 6px;
}

.apexcharts-canvas ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}


.apexcharts-inner {
    position: relative;
}

.apexcharts-text tspan {
    font-family: inherit;
}

.legend-mouseover-inactive {
    transition: 0.15s ease all;
    opacity: 0.20;
}

.apexcharts-series-collapsed {
    opacity: 0;
}

.apexcharts-tooltip {
    border-radius: 5px;
    box-shadow: 2px 2px 6px -4px #999;
    cursor: default;
    font-size: 14px;
    left: 62px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 20px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    white-space: nowrap;
    z-index: 12;
    transition: 0.15s ease all;
}

.apexcharts-tooltip.apexcharts-active {
    opacity: 1;
    transition: 0.15s ease all;
}

.apexcharts-tooltip.apexcharts-theme-light {
    border: 1px solid #e3e3e3;
    background: rgba(255, 255, 255, 0.96);
}

.apexcharts-tooltip.apexcharts-theme-dark {
    color: #fff;
    background: rgba(30, 30, 30, 0.8);
}

.apexcharts-tooltip * {
    font-family: inherit;
}


.apexcharts-tooltip-title {
    padding: 6px;
    font-size: 15px;
    margin-bottom: 4px;
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
    background: #ECEFF1;
    border-bottom: 1px solid #ddd;
}

.apexcharts-tooltip.apexcharts-theme-dark .apexcharts-tooltip-title {
    background: rgba(0, 0, 0, 0.7);
    border-bottom: 1px solid #333;
}

.apexcharts-tooltip-text-value,
.apexcharts-tooltip-text-z-value {
    display: inline-block;
    font-weight: 600;
    margin-left: 5px;
}

.apexcharts-tooltip-text-z-label:empty,
.apexcharts-tooltip-text-z-value:empty {
    display: none;
}

.apexcharts-tooltip-text-value,
.apexcharts-tooltip-text-z-value {
    font-weight: 600;
}

.apexcharts-tooltip-marker {
    width: 12px;
    height: 12px;
    position: relative;
    top: 0px;
    margin-right: 10px;
    border-radius: 50%;
}

.apexcharts-tooltip-series-group {
    padding: 0 10px;
    display: none;
    text-align: left;
    justify-content: left;
    align-items: center;
}

.apexcharts-tooltip-series-group.apexcharts-active .apexcharts-tooltip-marker {
    opacity: 1;
}

.apexcharts-tooltip-series-group.apexcharts-active,
.apexcharts-tooltip-series-group:last-child {
    padding-bottom: 4px;
}

.apexcharts-tooltip-series-group-hidden {
    opacity: 0;
    height: 0;
    line-height: 0;
    padding: 0 !important;
}

.apexcharts-tooltip-y-group {
    padding: 6px 0 5px;
}

.apexcharts-tooltip-candlestick {
    padding: 4px 8px;
}

.apexcharts-tooltip-candlestick>div {
    margin: 4px 0;
}

.apexcharts-tooltip-candlestick span.value {
    font-weight: bold;
}

.apexcharts-tooltip-rangebar {
    padding: 5px 8px;
}

.apexcharts-tooltip-rangebar .category {
    font-weight: 600;
    color: #777;
}

.apexcharts-tooltip-rangebar .series-name {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
}

.apexcharts-xaxistooltip {
    opacity: 0;
    padding: 9px 10px;
    pointer-events: none;
    color: #373d3f;
    font-size: 13px;
    text-align: center;
    border-radius: 2px;
    position: absolute;
    z-index: 10;
    background: #ECEFF1;
    border: 1px solid #90A4AE;
    transition: 0.15s ease all;
}

.apexcharts-xaxistooltip.apexcharts-theme-dark {
    background: rgba(0, 0, 0, 0.7);
    border: 1px solid rgba(0, 0, 0, 0.5);
    color: #fff;
}

.apexcharts-xaxistooltip:after,
.apexcharts-xaxistooltip:before {
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.apexcharts-xaxistooltip:after {
    border-color: rgba(236, 239, 241, 0);
    border-width: 6px;
    margin-left: -6px;
}

.apexcharts-xaxistooltip:before {
    border-color: rgba(144, 164, 174, 0);
    border-width: 7px;
    margin-left: -7px;
}

.apexcharts-xaxistooltip-bottom:after,
.apexcharts-xaxistooltip-bottom:before {
    bottom: 100%;
}

.apexcharts-xaxistooltip-top:after,
.apexcharts-xaxistooltip-top:before {
    top: 100%;
}

.apexcharts-xaxistooltip-bottom:after {
    border-bottom-color: #ECEFF1;
}

.apexcharts-xaxistooltip-bottom:before {
    border-bottom-color: #90A4AE;
}

.apexcharts-xaxistooltip-bottom.apexcharts-theme-dark:after {
    border-bottom-color: rgba(0, 0, 0, 0.5);
}

.apexcharts-xaxistooltip-bottom.apexcharts-theme-dark:before {
    border-bottom-color: rgba(0, 0, 0, 0.5);
}

.apexcharts-xaxistooltip-top:after {
    border-top-color: #ECEFF1
}

.apexcharts-xaxistooltip-top:before {
    border-top-color: #90A4AE;
}

.apexcharts-xaxistooltip-top.apexcharts-theme-dark:after {
    border-top-color: rgba(0, 0, 0, 0.5);
}

.apexcharts-xaxistooltip-top.apexcharts-theme-dark:before {
    border-top-color: rgba(0, 0, 0, 0.5);
}

.apexcharts-xaxistooltip.apexcharts-active {
    opacity: 1;
    transition: 0.15s ease all;
}

.apexcharts-yaxistooltip {
    opacity: 0;
    padding: 4px 10px;
    pointer-events: none;
    color: #373d3f;
    font-size: 13px;
    text-align: center;
    border-radius: 2px;
    position: absolute;
    z-index: 10;
    background: #ECEFF1;
    border: 1px solid #90A4AE;
}

.apexcharts-yaxistooltip.apexcharts-theme-dark {
    background: rgba(0, 0, 0, 0.7);
    border: 1px solid rgba(0, 0, 0, 0.5);
    color: #fff;
}

.apexcharts-yaxistooltip:after,
.apexcharts-yaxistooltip:before {
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.apexcharts-yaxistooltip:after {
    border-color: rgba(236, 239, 241, 0);
    border-width: 6px;
    margin-top: -6px;
}

.apexcharts-yaxistooltip:before {
    border-color: rgba(144, 164, 174, 0);
    border-width: 7px;
    margin-top: -7px;
}

.apexcharts-yaxistooltip-left:after,
.apexcharts-yaxistooltip-left:before {
    left: 100%;
}

.apexcharts-yaxistooltip-right:after,
.apexcharts-yaxistooltip-right:before {
    right: 100%;
}

.apexcharts-yaxistooltip-left:after {
    border-left-color: #ECEFF1;
}

.apexcharts-yaxistooltip-left:before {
    border-left-color: #90A4AE;
}

.apexcharts-yaxistooltip-left.apexcharts-theme-dark:after {
    border-left-color: rgba(0, 0, 0, 0.5);
}

.apexcharts-yaxistooltip-left.apexcharts-theme-dark:before {
    border-left-color: rgba(0, 0, 0, 0.5);
}

.apexcharts-yaxistooltip-right:after {
    border-right-color: #ECEFF1;
}

.apexcharts-yaxistooltip-right:before {
    border-right-color: #90A4AE;
}

.apexcharts-yaxistooltip-right.apexcharts-theme-dark:after {
    border-right-color: rgba(0, 0, 0, 0.5);
}

.apexcharts-yaxistooltip-right.apexcharts-theme-dark:before {
    border-right-color: rgba(0, 0, 0, 0.5);
}

.apexcharts-yaxistooltip.apexcharts-active {
    opacity: 1;
}

.apexcharts-yaxistooltip-hidden {
    display: none;
}

.apexcharts-xcrosshairs,
.apexcharts-ycrosshairs {
    pointer-events: none;
    opacity: 0;
    transition: 0.15s ease all;
}

.apexcharts-xcrosshairs.apexcharts-active,
.apexcharts-ycrosshairs.apexcharts-active {
    opacity: 1;
    transition: 0.15s ease all;
}

.apexcharts-ycrosshairs-hidden {
    opacity: 0;
}

.apexcharts-selection-rect {
    cursor: move;
}

.svg_select_boundingRect, .svg_select_points_rot {
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
}
.apexcharts-selection-rect + g .svg_select_boundingRect,
.apexcharts-selection-rect + g .svg_select_points_rot {
    opacity: 0;
    visibility: hidden;
}

.apexcharts-selection-rect + g .svg_select_points_l,
.apexcharts-selection-rect + g .svg_select_points_r {
    cursor: ew-resize;
    opacity: 1;
    visibility: visible;
}

.svg_select_points {
    fill: #efefef;
    stroke: #333;
    rx: 2;
}

.apexcharts-svg.apexcharts-zoomable.hovering-zoom {
    cursor: crosshair
}

.apexcharts-svg.apexcharts-zoomable.hovering-pan {
    cursor: move
}

.apexcharts-zoom-icon,
.apexcharts-zoomin-icon,
.apexcharts-zoomout-icon,
.apexcharts-reset-icon,
.apexcharts-pan-icon,
.apexcharts-selection-icon,
.apexcharts-menu-icon,
.apexcharts-toolbar-custom-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
    line-height: 24px;
    color: #6E8192;
    text-align: center;
}

.apexcharts-zoom-icon svg,
.apexcharts-zoomin-icon svg,
.apexcharts-zoomout-icon svg,
.apexcharts-reset-icon svg,
.apexcharts-menu-icon svg {
    fill: #6E8192;
}

.apexcharts-selection-icon svg {
    fill: #444;
    transform: scale(0.76)
}

.apexcharts-theme-dark .apexcharts-zoom-icon svg,
.apexcharts-theme-dark .apexcharts-zoomin-icon svg,
.apexcharts-theme-dark .apexcharts-zoomout-icon svg,
.apexcharts-theme-dark .apexcharts-reset-icon svg,
.apexcharts-theme-dark .apexcharts-pan-icon svg,
.apexcharts-theme-dark .apexcharts-selection-icon svg,
.apexcharts-theme-dark .apexcharts-menu-icon svg,
.apexcharts-theme-dark .apexcharts-toolbar-custom-icon svg {
    fill: #f3f4f5;
}

.apexcharts-canvas .apexcharts-zoom-icon.apexcharts-selected svg,
.apexcharts-canvas .apexcharts-selection-icon.apexcharts-selected svg,
.apexcharts-canvas .apexcharts-reset-zoom-icon.apexcharts-selected svg {
    fill: #008FFB;
}

.apexcharts-theme-light .apexcharts-selection-icon:not(.apexcharts-selected):hover svg,
.apexcharts-theme-light .apexcharts-zoom-icon:not(.apexcharts-selected):hover svg,
.apexcharts-theme-light .apexcharts-zoomin-icon:hover svg,
.apexcharts-theme-light .apexcharts-zoomout-icon:hover svg,
.apexcharts-theme-light .apexcharts-reset-icon:hover svg,
.apexcharts-theme-light .apexcharts-menu-icon:hover svg {
    fill: #333;
}

.apexcharts-selection-icon,
.apexcharts-menu-icon {
    position: relative;
}

.apexcharts-reset-icon {
    margin-left: 5px;
}

.apexcharts-zoom-icon,
.apexcharts-reset-icon,
.apexcharts-menu-icon {
    transform: scale(0.85);
}

.apexcharts-zoomin-icon,
.apexcharts-zoomout-icon {
    transform: scale(0.7)
}

.apexcharts-zoomout-icon {
    margin-right: 3px;
}

.apexcharts-pan-icon {
    transform: scale(0.62);
    position: relative;
    left: 1px;
    top: 0px;
}

.apexcharts-pan-icon svg {
    fill: #fff;
    stroke: #6E8192;
    stroke-width: 2;
}

.apexcharts-pan-icon.apexcharts-selected svg {
    stroke: #008FFB;
}

.apexcharts-pan-icon:not(.apexcharts-selected):hover svg {
    stroke: #333;
}

.apexcharts-toolbar {
    position: absolute;
    z-index: 11;
    max-width: 176px;
    text-align: right;
    border-radius: 3px;
    padding: 0px 6px 2px 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.apexcharts-menu {
    background: #fff;
    position: absolute;
    top: 100%;
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 3px;
    right: 10px;
    opacity: 0;
    min-width: 110px;
    transition: 0.15s ease all;
    pointer-events: none;
}

.apexcharts-menu.apexcharts-menu-open {
    opacity: 1;
    pointer-events: all;
    transition: 0.15s ease all;
}

.apexcharts-menu-item {
    padding: 6px 7px;
    font-size: 12px;
    cursor: pointer;
}

.apexcharts-theme-light .apexcharts-menu-item:hover {
    background: #eee;
}

.apexcharts-theme-dark .apexcharts-menu {
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
}

@media screen and (min-width: 768px) {
    .apexcharts-canvas:hover .apexcharts-toolbar {
        opacity: 1;
    }
}

.apexcharts-datalabel.apexcharts-element-hidden {
    opacity: 0;
}

.apexcharts-pie-label,
.apexcharts-datalabels,
.apexcharts-datalabel,
.apexcharts-datalabel-label,
.apexcharts-datalabel-value {
    cursor: default;
    pointer-events: none;
}

.apexcharts-pie-label-delay {
    opacity: 0;
    animation-name: opaque;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
}

.apexcharts-canvas .apexcharts-element-hidden {
    opacity: 0;
}

.apexcharts-hide .apexcharts-series-points {
    opacity: 0;
}

.apexcharts-gridline,
.apexcharts-annotation-rect,
.apexcharts-tooltip .apexcharts-marker,
.apexcharts-area-series .apexcharts-area,
.apexcharts-line,
.apexcharts-zoom-rect,
.apexcharts-toolbar svg,
.apexcharts-area-series .apexcharts-series-markers .apexcharts-marker.no-pointer-events,
.apexcharts-line-series .apexcharts-series-markers .apexcharts-marker.no-pointer-events,
.apexcharts-radar-series path,
.apexcharts-radar-series polygon {
    pointer-events: none;
}


/* markers */

.apexcharts-marker {
    transition: 0.15s ease all;
}

@keyframes opaque {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


/* Resize generated styles */

@keyframes resizeanim {
    from {
        opacity: 0;
    }
    to {
        opacity: 0;
    }
}

.resize-triggers {
    animation: 1ms resizeanim;
    visibility: hidden;
    opacity: 0;
}

.resize-triggers,
.resize-triggers>div,
.contract-trigger:before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.resize-triggers>div {
    background: #eee;
    overflow: auto;
}

.contract-trigger:before {
    width: 200%;
    height: 200%;
}
.MuiPaper-root {
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;
}
.MuiPaper-rounded {
    border-radius: 0.5rem;
}
.MuiPaper-outlined {
    border: 1px solid rgba(0, 0, 0, 0.12);
}
.MuiPaper-elevation0 {
    box-shadow: none;
}
.MuiPaper-elevation1 {
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
}
.MuiPaper-elevation2 {
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
}
.MuiPaper-elevation3 {
    box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12);
}
.MuiPaper-elevation4 {
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
}
.MuiPaper-elevation5 {
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12);
}
.MuiPaper-elevation6 {
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12);
}
.MuiPaper-elevation7 {
    box-shadow: 0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12);
}
.MuiPaper-elevation8 {
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12);
}
.MuiPaper-elevation9 {
    box-shadow: 0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12);
}
.MuiPaper-elevation10 {
    box-shadow: 0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12);
}
.MuiPaper-elevation11 {
    box-shadow: 0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12);
}
.MuiPaper-elevation12 {
    box-shadow: 0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12);
}
.MuiPaper-elevation13 {
    box-shadow: 0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12);
}
.MuiPaper-elevation14 {
    box-shadow: 0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12);
}
.MuiPaper-elevation15 {
    box-shadow: 0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12);
}
.MuiPaper-elevation16 {
    box-shadow: 0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12);
}
.MuiPaper-elevation17 {
    box-shadow: 0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12);
}
.MuiPaper-elevation18 {
    box-shadow: 0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12);
}
.MuiPaper-elevation19 {
    box-shadow: 0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12);
}
.MuiPaper-elevation20 {
    box-shadow: 0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12);
}
.MuiPaper-elevation21 {
    box-shadow: 0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12);
}
.MuiPaper-elevation22 {
    box-shadow: 0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12);
}
.MuiPaper-elevation23 {
    box-shadow: 0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12);
}
.MuiPaper-elevation24 {
    box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12);
}
.MuiTouchRipple-root {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    overflow: hidden;
    position: absolute;
    border-radius: inherit;
    pointer-events: none;
}
.MuiTouchRipple-ripple {
    opacity: 0;
    position: absolute;
}
.MuiTouchRipple-rippleVisible {
    opacity: 0.3;
    animation: MuiTouchRipple-keyframes-enter 550ms cubic-bezier(0.4, 0, 0.2, 1);
    transform: scale(1);
}
.MuiTouchRipple-ripplePulsate {
    animation-duration: 200ms;
}
.MuiTouchRipple-child {
    width: 100%;
    height: 100%;
    display: block;
    opacity: 1;
    border-radius: 50%;
    background-color: currentColor;
}
.MuiTouchRipple-childLeaving {
    opacity: 0;
    animation: MuiTouchRipple-keyframes-exit 550ms cubic-bezier(0.4, 0, 0.2, 1);
}
.MuiTouchRipple-childPulsate {
    top: 0;
    left: 0;
    position: absolute;
    animation: MuiTouchRipple-keyframes-pulsate 2500ms cubic-bezier(0.4, 0, 0.2, 1) 200ms infinite;
}
@-webkit-keyframes MuiTouchRipple-keyframes-enter {
    0% {
        opacity: 0.1;
        transform: scale(0);
    }
    100% {
        opacity: 0.3;
        transform: scale(1);
    }
}
@-webkit-keyframes MuiTouchRipple-keyframes-exit {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@-webkit-keyframes MuiTouchRipple-keyframes-pulsate {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.92);
    }
    100% {
        transform: scale(1);
    }
}

.MuiButtonBase-root {
    color: inherit;
    border: 0;
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    outline: 0;
    padding: 0;
    position: relative;
    align-items: center;
    user-select: none;
    border-radius: 0;
    vertical-align: middle;
    -moz-appearance: none;
    justify-content: center;
    text-decoration: none;
    background-color: transparent;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}
.MuiButtonBase-root::-moz-focus-inner {
    border-style: none;
}
.MuiButtonBase-root.Mui-disabled {
    cursor: default;
    pointer-events: none;
}
@media print {
    .MuiButtonBase-root {
        -webkit-print-color-adjust: exact;
    }
}

.MuiSvgIcon-root {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
}
.MuiSvgIcon-colorPrimary {
    color: #5383ff;
}
.MuiSvgIcon-colorSecondary {
    color: #3d4977;
}
.MuiSvgIcon-colorAction {
    color: rgba(0, 0, 0, 0.54);
}
.MuiSvgIcon-colorError {
    color: #f83245;
}
.MuiSvgIcon-colorDisabled {
    color: rgba(0, 0, 0, 0.26);
}
.MuiSvgIcon-fontSizeInherit {
    font-size: inherit;
}
.MuiSvgIcon-fontSizeSmall {
    font-size: 1.25rem;
}
.MuiSvgIcon-fontSizeLarge {
    font-size: 2.1875rem;
}

.MuiButton-root {
    color: rgba(0, 0, 0, 0.87);
    padding: 6px 16px;
    font-size: 14px;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: normal;
    line-height: 1.75;
    border-radius: 0.5rem;
    letter-spacing: 0.02857em;
    text-transform: none;
}
.MuiButton-root:hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.04);
}
.MuiButton-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
}
@media (hover: none) {
    .MuiButton-root:hover {
        background-color: transparent;
    }
}
.MuiButton-root:hover.Mui-disabled {
    background-color: transparent;
}
.MuiButton-label {
    width: 100%;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
}
.MuiButton-text {
    padding: 6px 8px;
    padding-left: 14px;
    padding-right: 14px;
}
.MuiButton-textPrimary {
    color: #5383ff;
}
.MuiButton-textPrimary:hover {
    background-color: rgba(83, 131, 255, 0.04);
}
@media (hover: none) {
    .MuiButton-textPrimary:hover {
        background-color: transparent;
    }
}
.MuiButton-textSecondary {
    color: #3d4977;
}
.MuiButton-textSecondary:hover {
    background-color: rgba(61, 73, 119, 0.04);
}
@media (hover: none) {
    .MuiButton-textSecondary:hover {
        background-color: transparent;
    }
}
.MuiButton-outlined {
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 5px 15px;
}
.MuiButton-outlined.Mui-disabled {
    border: 1px solid rgba(0, 0, 0, 0.12);
}
.MuiButton-outlinedPrimary {
    color: #5383ff;
    border: 1px solid rgba(83, 131, 255, 0.5);
}
.MuiButton-outlinedPrimary:hover {
    border: 1px solid #5383ff;
    background-color: rgba(83, 131, 255, 0.04);
}
@media (hover: none) {
    .MuiButton-outlinedPrimary:hover {
        background-color: transparent;
    }
}
.MuiButton-outlinedSecondary {
    color: #3d4977;
    border: 1px solid rgba(61, 73, 119, 0.5);
}
.MuiButton-outlinedSecondary:hover {
    border: 1px solid #3d4977;
    background-color: rgba(61, 73, 119, 0.04);
}
.MuiButton-outlinedSecondary.Mui-disabled {
    border: 1px solid rgba(0, 0, 0, 0.26);
}
@media (hover: none) {
    .MuiButton-outlinedSecondary:hover {
        background-color: transparent;
    }
}
.MuiButton-contained {
    color: rgba(0, 0, 0, 0.87);
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
    background-color: #fefefe;
}
.MuiButton-contained:hover {
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
    background-color: #f8f9ff;
}
.MuiButton-contained.Mui-focusVisible {
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12);
}
.MuiButton-contained:active {
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12);
}
.MuiButton-contained.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12);
}
@media (hover: none) {
    .MuiButton-contained:hover {
        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
        background-color: #fefefe;
    }
}
.MuiButton-contained:hover.Mui-disabled {
    background-color: rgba(0, 0, 0, 0.12);
}
.MuiButton-containedPrimary {
    color: #fff;
    background-color: #5383ff;
}
.MuiButton-containedPrimary:hover {
    background-color: rgb(70, 111, 216);
}
@media (hover: none) {
    .MuiButton-containedPrimary:hover {
        background-color: #5383ff;
    }
}
.MuiButton-containedSecondary {
    color: #fff;
    background-color: #3d4977;
}
.MuiButton-containedSecondary:hover {
    background-color: rgb(51, 62, 101);
}
@media (hover: none) {
    .MuiButton-containedSecondary:hover {
        background-color: #3d4977;
    }
}
.MuiButton-disableElevation {
    box-shadow: none;
}
.MuiButton-disableElevation:hover {
    box-shadow: none;
}
.MuiButton-disableElevation.Mui-focusVisible {
    box-shadow: none;
}
.MuiButton-disableElevation:active {
    box-shadow: none;
}
.MuiButton-disableElevation.Mui-disabled {
    box-shadow: none;
}
.MuiButton-colorInherit {
    color: inherit;
    border-color: currentColor;
}
.MuiButton-textSizeSmall {
    padding: 4px 5px;
    font-size: 0.8125rem;
}
.MuiButton-textSizeLarge {
    padding: 8px 11px;
    font-size: 0.9375rem;
}
.MuiButton-outlinedSizeSmall {
    padding: 3px 9px;
    font-size: 0.8125rem;
}
.MuiButton-outlinedSizeLarge {
    padding: 7px 21px;
    font-size: 0.9375rem;
}
.MuiButton-containedSizeSmall {
    padding: 4px 10px;
    font-size: 0.8125rem;
    padding-left: 14px;
    padding-right: 14px;
}
.MuiButton-containedSizeLarge {
    padding: 8px 22px;
    font-size: 0.9375rem;
}
.MuiButton-fullWidth {
    width: 100%;
}
.MuiButton-startIcon {
    display: inherit;
    margin-left: -4px;
    margin-right: 8px;
}
.MuiButton-startIcon.MuiButton-iconSizeSmall {
    margin-left: -2px;
}
.MuiButton-endIcon {
    display: inherit;
    margin-left: 8px;
    margin-right: -4px;
}
.MuiButton-endIcon.MuiButton-iconSizeSmall {
    margin-right: -2px;
}
.MuiButton-iconSizeSmall > *:first-child {
    font-size: 18px;
}
.MuiButton-iconSizeMedium > *:first-child {
    font-size: 20px;
}
.MuiButton-iconSizeLarge > *:first-child {
    font-size: 22px;
}
.MuiDrawer-docked {
    flex: 0 0 auto;
}
.MuiDrawer-paper {
    top: 0;
    flex: 1 0 auto;
    height: 100%;
    display: flex;
    outline: 0;
    z-index: 1200;
    position: fixed;
    overflow-y: auto;
    flex-direction: column;
    -webkit-overflow-scrolling: touch;
}
.MuiDrawer-paperAnchorLeft {
    left: 0;
    right: auto;
}
.MuiDrawer-paperAnchorRight {
    left: auto;
    right: 0;
}
.MuiDrawer-paperAnchorTop {
    top: 0;
    left: 0;
    right: 0;
    bottom: auto;
    height: auto;
    max-height: 100%;
}
.MuiDrawer-paperAnchorBottom {
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    height: auto;
    max-height: 100%;
}
.MuiDrawer-paperAnchorDockedLeft {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.MuiDrawer-paperAnchorDockedTop {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.MuiDrawer-paperAnchorDockedRight {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.MuiDrawer-paperAnchorDockedBottom {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.MuiGrid-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
}
.MuiGrid-item {
    margin: 0;
    box-sizing: border-box;
}
.MuiGrid-zeroMinWidth {
    min-width: 0;
}
.MuiGrid-direction-xs-column {
    flex-direction: column;
}
.MuiGrid-direction-xs-column-reverse {
    flex-direction: column-reverse;
}
.MuiGrid-direction-xs-row-reverse {
    flex-direction: row-reverse;
}
.MuiGrid-wrap-xs-nowrap {
    flex-wrap: nowrap;
}
.MuiGrid-wrap-xs-wrap-reverse {
    flex-wrap: wrap-reverse;
}
.MuiGrid-align-items-xs-center {
    align-items: center;
}
.MuiGrid-align-items-xs-flex-start {
    align-items: flex-start;
}
.MuiGrid-align-items-xs-flex-end {
    align-items: flex-end;
}
.MuiGrid-align-items-xs-baseline {
    align-items: baseline;
}
.MuiGrid-align-content-xs-center {
    align-content: center;
}
.MuiGrid-align-content-xs-flex-start {
    align-content: flex-start;
}
.MuiGrid-align-content-xs-flex-end {
    align-content: flex-end;
}
.MuiGrid-align-content-xs-space-between {
    align-content: space-between;
}
.MuiGrid-align-content-xs-space-around {
    align-content: space-around;
}
.MuiGrid-justify-xs-center {
    justify-content: center;
}
.MuiGrid-justify-xs-flex-end {
    justify-content: flex-end;
}
.MuiGrid-justify-xs-space-between {
    justify-content: space-between;
}
.MuiGrid-justify-xs-space-around {
    justify-content: space-around;
}
.MuiGrid-justify-xs-space-evenly {
    justify-content: space-evenly;
}
.MuiGrid-spacing-xs-1 {
    width: calc(100% + 8px);
    margin: -4px;
}
.MuiGrid-spacing-xs-1 > .MuiGrid-item {
    padding: 4px;
}
.MuiGrid-spacing-xs-2 {
    width: calc(100% + 16px);
    margin: -8px;
}
.MuiGrid-spacing-xs-2 > .MuiGrid-item {
    padding: 8px;
}
.MuiGrid-spacing-xs-3 {
    width: calc(100% + 24px);
    margin: -12px;
}
.MuiGrid-spacing-xs-3 > .MuiGrid-item {
    padding: 12px;
}
.MuiGrid-spacing-xs-4 {
    width: calc(100% + 32px);
    margin: -16px;
}
.MuiGrid-spacing-xs-4 > .MuiGrid-item {
    padding: 16px;
}
.MuiGrid-spacing-xs-5 {
    width: calc(100% + 40px);
    margin: -20px;
}
.MuiGrid-spacing-xs-5 > .MuiGrid-item {
    padding: 20px;
}
.MuiGrid-spacing-xs-6 {
    width: calc(100% + 48px);
    margin: -24px;
}
.MuiGrid-spacing-xs-6 > .MuiGrid-item {
    padding: 24px;
}
.MuiGrid-spacing-xs-7 {
    width: calc(100% + 56px);
    margin: -28px;
}
.MuiGrid-spacing-xs-7 > .MuiGrid-item {
    padding: 28px;
}
.MuiGrid-spacing-xs-8 {
    width: calc(100% + 64px);
    margin: -32px;
}
.MuiGrid-spacing-xs-8 > .MuiGrid-item {
    padding: 32px;
}
.MuiGrid-spacing-xs-9 {
    width: calc(100% + 72px);
    margin: -36px;
}
.MuiGrid-spacing-xs-9 > .MuiGrid-item {
    padding: 36px;
}
.MuiGrid-spacing-xs-10 {
    width: calc(100% + 80px);
    margin: -40px;
}
.MuiGrid-spacing-xs-10 > .MuiGrid-item {
    padding: 40px;
}
.MuiGrid-grid-xs-auto {
    flex-grow: 0;
    max-width: none;
    flex-basis: auto;
}
.MuiGrid-grid-xs-true {
    flex-grow: 1;
    max-width: 100%;
    flex-basis: 0;
}
.MuiGrid-grid-xs-1 {
    flex-grow: 0;
    max-width: 8.333333%;
    flex-basis: 8.333333%;
}
.MuiGrid-grid-xs-2 {
    flex-grow: 0;
    max-width: 16.666667%;
    flex-basis: 16.666667%;
}
.MuiGrid-grid-xs-3 {
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%;
}
.MuiGrid-grid-xs-4 {
    flex-grow: 0;
    max-width: 33.333333%;
    flex-basis: 33.333333%;
}
.MuiGrid-grid-xs-5 {
    flex-grow: 0;
    max-width: 41.666667%;
    flex-basis: 41.666667%;
}
.MuiGrid-grid-xs-6 {
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%;
}
.MuiGrid-grid-xs-7 {
    flex-grow: 0;
    max-width: 58.333333%;
    flex-basis: 58.333333%;
}
.MuiGrid-grid-xs-8 {
    flex-grow: 0;
    max-width: 66.666667%;
    flex-basis: 66.666667%;
}
.MuiGrid-grid-xs-9 {
    flex-grow: 0;
    max-width: 75%;
    flex-basis: 75%;
}
.MuiGrid-grid-xs-10 {
    flex-grow: 0;
    max-width: 83.333333%;
    flex-basis: 83.333333%;
}
.MuiGrid-grid-xs-11 {
    flex-grow: 0;
    max-width: 91.666667%;
    flex-basis: 91.666667%;
}
.MuiGrid-grid-xs-12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
}
@media (min-width:600px) {
    .MuiGrid-grid-sm-auto {
        flex-grow: 0;
        max-width: none;
        flex-basis: auto;
    }
    .MuiGrid-grid-sm-true {
        flex-grow: 1;
        max-width: 100%;
        flex-basis: 0;
    }
    .MuiGrid-grid-sm-1 {
        flex-grow: 0;
        max-width: 8.333333%;
        flex-basis: 8.333333%;
    }
    .MuiGrid-grid-sm-2 {
        flex-grow: 0;
        max-width: 16.666667%;
        flex-basis: 16.666667%;
    }
    .MuiGrid-grid-sm-3 {
        flex-grow: 0;
        max-width: 25%;
        flex-basis: 25%;
    }
    .MuiGrid-grid-sm-4 {
        flex-grow: 0;
        max-width: 33.333333%;
        flex-basis: 33.333333%;
    }
    .MuiGrid-grid-sm-5 {
        flex-grow: 0;
        max-width: 41.666667%;
        flex-basis: 41.666667%;
    }
    .MuiGrid-grid-sm-6 {
        flex-grow: 0;
        max-width: 50%;
        flex-basis: 50%;
    }
    .MuiGrid-grid-sm-7 {
        flex-grow: 0;
        max-width: 58.333333%;
        flex-basis: 58.333333%;
    }
    .MuiGrid-grid-sm-8 {
        flex-grow: 0;
        max-width: 66.666667%;
        flex-basis: 66.666667%;
    }
    .MuiGrid-grid-sm-9 {
        flex-grow: 0;
        max-width: 75%;
        flex-basis: 75%;
    }
    .MuiGrid-grid-sm-10 {
        flex-grow: 0;
        max-width: 83.333333%;
        flex-basis: 83.333333%;
    }
    .MuiGrid-grid-sm-11 {
        flex-grow: 0;
        max-width: 91.666667%;
        flex-basis: 91.666667%;
    }
    .MuiGrid-grid-sm-12 {
        flex-grow: 0;
        max-width: 100%;
        flex-basis: 100%;
    }
}
@media (min-width:960px) {
    .MuiGrid-grid-md-auto {
        flex-grow: 0;
        max-width: none;
        flex-basis: auto;
    }
    .MuiGrid-grid-md-true {
        flex-grow: 1;
        max-width: 100%;
        flex-basis: 0;
    }
    .MuiGrid-grid-md-1 {
        flex-grow: 0;
        max-width: 8.333333%;
        flex-basis: 8.333333%;
    }
    .MuiGrid-grid-md-2 {
        flex-grow: 0;
        max-width: 16.666667%;
        flex-basis: 16.666667%;
    }
    .MuiGrid-grid-md-3 {
        flex-grow: 0;
        max-width: 25%;
        flex-basis: 25%;
    }
    .MuiGrid-grid-md-4 {
        flex-grow: 0;
        max-width: 33.333333%;
        flex-basis: 33.333333%;
    }
    .MuiGrid-grid-md-5 {
        flex-grow: 0;
        max-width: 41.666667%;
        flex-basis: 41.666667%;
    }
    .MuiGrid-grid-md-6 {
        flex-grow: 0;
        max-width: 50%;
        flex-basis: 50%;
    }
    .MuiGrid-grid-md-7 {
        flex-grow: 0;
        max-width: 58.333333%;
        flex-basis: 58.333333%;
    }
    .MuiGrid-grid-md-8 {
        flex-grow: 0;
        max-width: 66.666667%;
        flex-basis: 66.666667%;
    }
    .MuiGrid-grid-md-9 {
        flex-grow: 0;
        max-width: 75%;
        flex-basis: 75%;
    }
    .MuiGrid-grid-md-10 {
        flex-grow: 0;
        max-width: 83.333333%;
        flex-basis: 83.333333%;
    }
    .MuiGrid-grid-md-11 {
        flex-grow: 0;
        max-width: 91.666667%;
        flex-basis: 91.666667%;
    }
    .MuiGrid-grid-md-12 {
        flex-grow: 0;
        max-width: 100%;
        flex-basis: 100%;
    }
}
@media (min-width:1280px) {
    .MuiGrid-grid-lg-auto {
        flex-grow: 0;
        max-width: none;
        flex-basis: auto;
    }
    .MuiGrid-grid-lg-true {
        flex-grow: 1;
        max-width: 100%;
        flex-basis: 0;
    }
    .MuiGrid-grid-lg-1 {
        flex-grow: 0;
        max-width: 8.333333%;
        flex-basis: 8.333333%;
    }
    .MuiGrid-grid-lg-2 {
        flex-grow: 0;
        max-width: 16.666667%;
        flex-basis: 16.666667%;
    }
    .MuiGrid-grid-lg-3 {
        flex-grow: 0;
        max-width: 25%;
        flex-basis: 25%;
    }
    .MuiGrid-grid-lg-4 {
        flex-grow: 0;
        max-width: 33.333333%;
        flex-basis: 33.333333%;
    }
    .MuiGrid-grid-lg-5 {
        flex-grow: 0;
        max-width: 41.666667%;
        flex-basis: 41.666667%;
    }
    .MuiGrid-grid-lg-6 {
        flex-grow: 0;
        max-width: 50%;
        flex-basis: 50%;
    }
    .MuiGrid-grid-lg-7 {
        flex-grow: 0;
        max-width: 58.333333%;
        flex-basis: 58.333333%;
    }
    .MuiGrid-grid-lg-8 {
        flex-grow: 0;
        max-width: 66.666667%;
        flex-basis: 66.666667%;
    }
    .MuiGrid-grid-lg-9 {
        flex-grow: 0;
        max-width: 75%;
        flex-basis: 75%;
    }
    .MuiGrid-grid-lg-10 {
        flex-grow: 0;
        max-width: 83.333333%;
        flex-basis: 83.333333%;
    }
    .MuiGrid-grid-lg-11 {
        flex-grow: 0;
        max-width: 91.666667%;
        flex-basis: 91.666667%;
    }
    .MuiGrid-grid-lg-12 {
        flex-grow: 0;
        max-width: 100%;
        flex-basis: 100%;
    }
}
@media (min-width:1920px) {
    .MuiGrid-grid-xl-auto {
        flex-grow: 0;
        max-width: none;
        flex-basis: auto;
    }
    .MuiGrid-grid-xl-true {
        flex-grow: 1;
        max-width: 100%;
        flex-basis: 0;
    }
    .MuiGrid-grid-xl-1 {
        flex-grow: 0;
        max-width: 8.333333%;
        flex-basis: 8.333333%;
    }
    .MuiGrid-grid-xl-2 {
        flex-grow: 0;
        max-width: 16.666667%;
        flex-basis: 16.666667%;
    }
    .MuiGrid-grid-xl-3 {
        flex-grow: 0;
        max-width: 25%;
        flex-basis: 25%;
    }
    .MuiGrid-grid-xl-4 {
        flex-grow: 0;
        max-width: 33.333333%;
        flex-basis: 33.333333%;
    }
    .MuiGrid-grid-xl-5 {
        flex-grow: 0;
        max-width: 41.666667%;
        flex-basis: 41.666667%;
    }
    .MuiGrid-grid-xl-6 {
        flex-grow: 0;
        max-width: 50%;
        flex-basis: 50%;
    }
    .MuiGrid-grid-xl-7 {
        flex-grow: 0;
        max-width: 58.333333%;
        flex-basis: 58.333333%;
    }
    .MuiGrid-grid-xl-8 {
        flex-grow: 0;
        max-width: 66.666667%;
        flex-basis: 66.666667%;
    }
    .MuiGrid-grid-xl-9 {
        flex-grow: 0;
        max-width: 75%;
        flex-basis: 75%;
    }
    .MuiGrid-grid-xl-10 {
        flex-grow: 0;
        max-width: 83.333333%;
        flex-basis: 83.333333%;
    }
    .MuiGrid-grid-xl-11 {
        flex-grow: 0;
        max-width: 91.666667%;
        flex-basis: 91.666667%;
    }
    .MuiGrid-grid-xl-12 {
        flex-grow: 0;
        max-width: 100%;
        flex-basis: 100%;
    }
}

.MuiContainer-root {
    width: 100%;
    display: block;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
}
@media (min-width:600px) {
    .MuiContainer-root {
        padding-left: 24px;
        padding-right: 24px;
    }
}
.MuiContainer-disableGutters {
    padding-left: 0;
    padding-right: 0;
}
@media (min-width:600px) {
    .MuiContainer-fixed {
        max-width: 600px;
    }
}
@media (min-width:960px) {
    .MuiContainer-fixed {
        max-width: 960px;
    }
}
@media (min-width:1280px) {
    .MuiContainer-fixed {
        max-width: 1280px;
    }
}
@media (min-width:1920px) {
    .MuiContainer-fixed {
        max-width: 1920px;
    }
}
@media (min-width:0px) {
    .MuiContainer-maxWidthXs {
        max-width: 444px;
    }
}
@media (min-width:600px) {
    .MuiContainer-maxWidthSm {
        max-width: 600px;
    }
}
@media (min-width:960px) {
    .MuiContainer-maxWidthMd {
        max-width: 960px;
    }
}
@media (min-width:1280px) {
    .MuiContainer-maxWidthLg {
        max-width: 1280px;
    }
}
@media (min-width:1920px) {
    .MuiContainer-maxWidthXl {
        max-width: 1920px;
    }
}
.MuiFab-root {
    color: rgba(0, 0, 0, 0.87);
    width: 56px;
    height: 56px;
    padding: 0;
    font-size: 14px;
    min-width: 0;
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12);
    box-sizing: border-box;
    min-height: 36px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 50%;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    background-color: #fefefe;
}
.MuiFab-root:active {
    box-shadow: 0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12);
}
.MuiFab-root:hover {
    text-decoration: none;
    background-color: #f8f9ff;
}
.MuiFab-root.Mui-focusVisible {
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12);
}
.MuiFab-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12);
}
@media (hover: none) {
    .MuiFab-root:hover {
        background-color: #fefefe;
    }
}
.MuiFab-root:hover.Mui-disabled {
    background-color: rgba(0, 0, 0, 0.12);
}
.MuiFab-label {
    width: 100%;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
}
.MuiFab-primary {
    color: #fff;
    background-color: #5383ff;
}
.MuiFab-primary:hover {
    background-color: rgb(70, 111, 216);
}
@media (hover: none) {
    .MuiFab-primary:hover {
        background-color: #5383ff;
    }
}
.MuiFab-secondary {
    color: #fff;
    background-color: #3d4977;
}
.MuiFab-secondary:hover {
    background-color: rgb(51, 62, 101);
}
@media (hover: none) {
    .MuiFab-secondary:hover {
        background-color: #3d4977;
    }
}
.MuiFab-extended {
    width: auto;
    height: 48px;
    padding: 0 16px;
    min-width: 48px;
    min-height: auto;
    border-radius: 24px;
}
.MuiFab-extended.MuiFab-sizeSmall {
    width: auto;
    height: 34px;
    padding: 0 8px;
    min-width: 34px;
    border-radius: 17px;
}
.MuiFab-extended.MuiFab-sizeMedium {
    width: auto;
    height: 40px;
    padding: 0 16px;
    min-width: 40px;
    border-radius: 20px;
}
.MuiFab-colorInherit {
    color: inherit;
}
.MuiFab-sizeSmall {
    width: 40px;
    height: 40px;
}
.MuiFab-sizeMedium {
    width: 48px;
    height: 48px;
}
.MuiCard-root {
    overflow: hidden;
}
.MuiCardContent-root {
    padding: 16px;
}
.MuiCardContent-root:last-child {
    padding-bottom: 24px;
}

.red_dot {
    color: red;
}
.small_dot {
    font-size: 14px;
    font-style: italic;
}
.medium_dot {
    font-size: 16px;
}
.product_italic {
    font-style: italic;
}
.nodisplay {
    display: none;
}
.capthca_center{
    text-align: center;
    width: fit-content;
    margin: auto;
    margin-top: 20px;
}
.reg_link:link { text-decoration: none; color: #1f8ded }
.reg_link:visited { text-decoration: none; color: #1f8ded }
.reg_link:hover { text-decoration: underline; color: #1f8ded }
.reg_link:active { text-decoration: none; color: #1f8ded}