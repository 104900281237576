.menu_link:link { text-decoration: none; }
.menu_link:visited { text-decoration: none; }
.menu_link:hover { text-decoration: none; }
.menu_link:active { text-decoration: none; }

.menu_link_flat:link { text-decoration: none; color: white !important; font-size: larger;}
.menu_link_flat:visited { text-decoration: none; color: white !important; font-size: larger;}
.menu_link_flat:hover { text-decoration: none; color: white !important; font-size: larger;}
.menu_link_flat:active { text-decoration: none; color: white !important; font-size: larger;}

.menu_item_text{
    font-size: larger;
}

.menu_wrapper {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
}
.menu_item {
    margin-left: 35px;
}
.logo_wrapper {
    height: 150px;
    width: 200px;
    margin-top: -100px;
}
.menu_down_arrow{
    width: 20px;
    height: 20px;
}