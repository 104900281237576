.terms_and_conditions_ol {
    list-style-type:lower-latin;
}

.content__narrow__mid {
    width: 730px;
}

.reg_link:link { text-decoration: none; color: #1f8ded }
.reg_link:visited { text-decoration: none; color: #1f8ded }
.reg_link:hover { text-decoration: underline; color: #1f8ded }
.reg_link:active { text-decoration: none; color: #1f8ded}

.marge-left-10{
    margin-right: 15px !important;
}