/*@import url("https://fonts.intercomcdn.com/proxima-nova/proxima-nova-all.css");*/

html {
    color: #222;
    font-size: 1em;
    line-height: 1.4
}

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none
}

::selection {
    background: #b3d4fc;
    text-shadow: none
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0
}

audio, canvas, iframe, img, svg, video {
    vertical-align: middle
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0
}

textarea {
    resize: vertical
}

html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 100%;
    min-height: 100%
}

html *, html *:before, html *:after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit
}

body {
    font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 1.6;
    color: #565867;
    font-weight: 400;
    background: #f3f5f7;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    -moz-osx-font-smoothing: grayscale;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0;
    height: 100%;
    min-height: 100%
}

img, video, svg {
    max-width: 100%
}

a {
    color: inherit
}

a:hover {
    text-decoration: none
}

.container {
    padding-left: 40px;
    padding-right: 40px;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto
}

@media (max-width: 767px) {
    .container {
        padding-left: 40px;
        padding-right: 40px
    }
}

@media (max-width: 600px) {
    .container {
        padding-left: 20px;
        padding-right: 20px
    }
}

.content {
    max-width: 100%;
    width: 900px;
    margin-left: auto;
    margin-right: auto
}

.content__narrow {
    width: 640px
}

h1, h2, h3, h4, h5, h6 {
    line-height: 1.24;
    font-weight: 400;
    color: #3a3c4c
}

.intercom-messenger-auto-opened .intercom-sheet-header-close-button, .intercom-messenger-auto-opened .intercom-sheet-header-minimize-button, .intercom-messenger-auto-opened .intercom-launcher-button {
    display: none
}

.mo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.mo.o__centered {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.mo.o__reversed > .mo__aside {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    margin: 0 0 0 1em
}

.mo__body {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    min-width: 0;
    position: relative
}

.mo__aside {
    margin-right: 1rem
}

[type="text"], [type="email"], [type="number"], textarea {
    font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.t__h1 {
    margin: 0 0 17px;
    font-size: 33px;
    font-weight: normal
}

.t__h2 {
    margin: 0 0 2px;
    font-size: 22px
}

.t__h3 {
    margin: -5px 0 2px;
    font-size: 18px
}

.t__small {
    font-size: 14px
}

.t__h4 {
    font-size: 18px
}

.t__h4-section {
    position: relative;
    top: 12px
}

@media (max-width: 600px) {
    .t__h1 {
        font-size: 34px
    }

    .t__h2 {
        font-size: 30px
    }
}

.t__no-und {
    text-decoration: none
}

.c__light {
    color: #8f919d
}

.c__body-2 {
    color: #565867
}

.c__secondary {
    color: #6b6e85
}

.c__darker {
    color: #4f5e6b
}

.g__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: -26px -13px 0
}

.no-flexbox .g__row:before, .no-flexbox .g__row:after {
    content: ' ';
    display: table
}

.no-flexbox .g__row:after {
    clear: both
}

.g__row + .g__row {
    margin-top: 0
}

[class^=g__c], [class*=" g__c"] {
    padding: 26px 13px 0;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.g__c1 {
    -ms-flex-preferred-size: 8.3333333333%;
    flex-basis: 8.3333333333%;
    width: 8.3333333333%
}

.no-flexbox .g__c1 {
    float: left
}

.g__c2 {
    -ms-flex-preferred-size: 16.6666666667%;
    flex-basis: 16.6666666667%;
    width: 16.6666666667%
}

.no-flexbox .g__c2 {
    float: left
}

.g__c3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    width: 25%
}

.no-flexbox .g__c3 {
    float: left
}

.g__c4 {
    -ms-flex-preferred-size: 33.3333333333%;
    flex-basis: 33.3333333333%;
    width: 33.3333333333%
}

.no-flexbox .g__c4 {
    float: left
}

.g__c5 {
    -ms-flex-preferred-size: 41.6666666667%;
    flex-basis: 41.6666666667%;
    width: 41.6666666667%
}

.no-flexbox .g__c5 {
    float: left
}

.g__c6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    width: 50%
}

.no-flexbox .g__c6 {
    float: left
}

.g__c7 {
    -ms-flex-preferred-size: 58.3333333333%;
    flex-basis: 58.3333333333%;
    width: 58.3333333333%
}

.no-flexbox .g__c7 {
    float: left
}

.g__c8 {
    -ms-flex-preferred-size: 66.6666666667%;
    flex-basis: 66.6666666667%;
    width: 66.6666666667%
}

.no-flexbox .g__c8 {
    float: left
}

.g__c9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    width: 75%
}

.no-flexbox .g__c9 {
    float: left
}

.g__c10 {
    -ms-flex-preferred-size: 83.3333333333%;
    flex-basis: 83.3333333333%;
    width: 83.3333333333%
}

.no-flexbox .g__c10 {
    float: left
}

.g__c11 {
    -ms-flex-preferred-size: 91.6666666667%;
    flex-basis: 91.6666666667%;
    width: 91.6666666667%
}

.no-flexbox .g__c11 {
    float: left
}

.g__c12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    width: 100%
}

.no-flexbox .g__c12 {
    float: left
}

@media (max-width: 600px) {
    .g__c1--sm {
        -ms-flex-preferred-size: 8.3333333333%;
        flex-basis: 8.3333333333%;
        width: 8.3333333333%
    }

    .no-flexbox .g__c1--sm {
        float: left
    }
}

@media (min-width: 601px) and (max-width: 767px) {
    .g__c1--md {
        -ms-flex-preferred-size: 8.3333333333%;
        flex-basis: 8.3333333333%;
        width: 8.3333333333%
    }

    .no-flexbox .g__c1--md {
        float: left
    }
}

@media (min-width: 768px) and (max-width: 1600px) {
    .g__c1--lg {
        -ms-flex-preferred-size: 8.3333333333%;
        flex-basis: 8.3333333333%;
        width: 8.3333333333%
    }

    .no-flexbox .g__c1--lg {
        float: left
    }
}

@media (min-width: 1601px) {
    .g__c1--xlg {
        -ms-flex-preferred-size: 8.3333333333%;
        flex-basis: 8.3333333333%;
        width: 8.3333333333%
    }

    .no-flexbox .g__c1--xlg {
        float: left
    }
}

@media (max-width: 600px) {
    .g__c2--sm {
        -ms-flex-preferred-size: 16.6666666667%;
        flex-basis: 16.6666666667%;
        width: 16.6666666667%
    }

    .no-flexbox .g__c2--sm {
        float: left
    }
}

@media (min-width: 601px) and (max-width: 767px) {
    .g__c2--md {
        -ms-flex-preferred-size: 16.6666666667%;
        flex-basis: 16.6666666667%;
        width: 16.6666666667%
    }

    .no-flexbox .g__c2--md {
        float: left
    }
}

@media (min-width: 768px) and (max-width: 1600px) {
    .g__c2--lg {
        -ms-flex-preferred-size: 16.6666666667%;
        flex-basis: 16.6666666667%;
        width: 16.6666666667%
    }

    .no-flexbox .g__c2--lg {
        float: left
    }
}

@media (min-width: 1601px) {
    .g__c2--xlg {
        -ms-flex-preferred-size: 16.6666666667%;
        flex-basis: 16.6666666667%;
        width: 16.6666666667%
    }

    .no-flexbox .g__c2--xlg {
        float: left
    }
}

@media (max-width: 600px) {
    .g__c3--sm {
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        width: 25%
    }

    .no-flexbox .g__c3--sm {
        float: left
    }
}

@media (min-width: 601px) and (max-width: 767px) {
    .g__c3--md {
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        width: 25%
    }

    .no-flexbox .g__c3--md {
        float: left
    }
}

@media (min-width: 768px) and (max-width: 1600px) {
    .g__c3--lg {
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        width: 25%
    }

    .no-flexbox .g__c3--lg {
        float: left
    }
}

@media (min-width: 1601px) {
    .g__c3--xlg {
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        width: 25%
    }

    .no-flexbox .g__c3--xlg {
        float: left
    }
}

@media (max-width: 600px) {
    .g__c4--sm {
        -ms-flex-preferred-size: 33.3333333333%;
        flex-basis: 33.3333333333%;
        width: 33.3333333333%
    }

    .no-flexbox .g__c4--sm {
        float: left
    }
}

@media (min-width: 601px) and (max-width: 767px) {
    .g__c4--md {
        -ms-flex-preferred-size: 33.3333333333%;
        flex-basis: 33.3333333333%;
        width: 33.3333333333%
    }

    .no-flexbox .g__c4--md {
        float: left
    }
}

@media (min-width: 768px) and (max-width: 1600px) {
    .g__c4--lg {
        -ms-flex-preferred-size: 33.3333333333%;
        flex-basis: 33.3333333333%;
        width: 33.3333333333%
    }

    .no-flexbox .g__c4--lg {
        float: left
    }
}

@media (min-width: 1601px) {
    .g__c4--xlg {
        -ms-flex-preferred-size: 33.3333333333%;
        flex-basis: 33.3333333333%;
        width: 33.3333333333%
    }

    .no-flexbox .g__c4--xlg {
        float: left
    }
}

@media (max-width: 600px) {
    .g__c5--sm {
        -ms-flex-preferred-size: 41.6666666667%;
        flex-basis: 41.6666666667%;
        width: 41.6666666667%
    }

    .no-flexbox .g__c5--sm {
        float: left
    }
}

@media (min-width: 601px) and (max-width: 767px) {
    .g__c5--md {
        -ms-flex-preferred-size: 41.6666666667%;
        flex-basis: 41.6666666667%;
        width: 41.6666666667%
    }

    .no-flexbox .g__c5--md {
        float: left
    }
}

@media (min-width: 768px) and (max-width: 1600px) {
    .g__c5--lg {
        -ms-flex-preferred-size: 41.6666666667%;
        flex-basis: 41.6666666667%;
        width: 41.6666666667%
    }

    .no-flexbox .g__c5--lg {
        float: left
    }
}

@media (min-width: 1601px) {
    .g__c5--xlg {
        -ms-flex-preferred-size: 41.6666666667%;
        flex-basis: 41.6666666667%;
        width: 41.6666666667%
    }

    .no-flexbox .g__c5--xlg {
        float: left
    }
}

@media (max-width: 600px) {
    .g__c6--sm {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        width: 50%
    }

    .no-flexbox .g__c6--sm {
        float: left
    }
}

@media (min-width: 601px) and (max-width: 767px) {
    .g__c6--md {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        width: 50%
    }

    .no-flexbox .g__c6--md {
        float: left
    }
}

@media (min-width: 768px) and (max-width: 1600px) {
    .g__c6--lg {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        width: 50%
    }

    .no-flexbox .g__c6--lg {
        float: left
    }
}

@media (min-width: 1601px) {
    .g__c6--xlg {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        width: 50%
    }

    .no-flexbox .g__c6--xlg {
        float: left
    }
}

@media (max-width: 600px) {
    .g__c7--sm {
        -ms-flex-preferred-size: 58.3333333333%;
        flex-basis: 58.3333333333%;
        width: 58.3333333333%
    }

    .no-flexbox .g__c7--sm {
        float: left
    }
}

@media (min-width: 601px) and (max-width: 767px) {
    .g__c7--md {
        -ms-flex-preferred-size: 58.3333333333%;
        flex-basis: 58.3333333333%;
        width: 58.3333333333%
    }

    .no-flexbox .g__c7--md {
        float: left
    }
}

@media (min-width: 768px) and (max-width: 1600px) {
    .g__c7--lg {
        -ms-flex-preferred-size: 58.3333333333%;
        flex-basis: 58.3333333333%;
        width: 58.3333333333%
    }

    .no-flexbox .g__c7--lg {
        float: left
    }
}

@media (min-width: 1601px) {
    .g__c7--xlg {
        -ms-flex-preferred-size: 58.3333333333%;
        flex-basis: 58.3333333333%;
        width: 58.3333333333%
    }

    .no-flexbox .g__c7--xlg {
        float: left
    }
}

@media (max-width: 600px) {
    .g__c8--sm {
        -ms-flex-preferred-size: 66.6666666667%;
        flex-basis: 66.6666666667%;
        width: 66.6666666667%
    }

    .no-flexbox .g__c8--sm {
        float: left
    }
}

@media (min-width: 601px) and (max-width: 767px) {
    .g__c8--md {
        -ms-flex-preferred-size: 66.6666666667%;
        flex-basis: 66.6666666667%;
        width: 66.6666666667%
    }

    .no-flexbox .g__c8--md {
        float: left
    }
}

@media (min-width: 768px) and (max-width: 1600px) {
    .g__c8--lg {
        -ms-flex-preferred-size: 66.6666666667%;
        flex-basis: 66.6666666667%;
        width: 66.6666666667%
    }

    .no-flexbox .g__c8--lg {
        float: left
    }
}

@media (min-width: 1601px) {
    .g__c8--xlg {
        -ms-flex-preferred-size: 66.6666666667%;
        flex-basis: 66.6666666667%;
        width: 66.6666666667%
    }

    .no-flexbox .g__c8--xlg {
        float: left
    }
}

@media (max-width: 600px) {
    .g__c9--sm {
        -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
        width: 75%
    }

    .no-flexbox .g__c9--sm {
        float: left
    }
}

@media (min-width: 601px) and (max-width: 767px) {
    .g__c9--md {
        -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
        width: 75%
    }

    .no-flexbox .g__c9--md {
        float: left
    }
}

@media (min-width: 768px) and (max-width: 1600px) {
    .g__c9--lg {
        -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
        width: 75%
    }

    .no-flexbox .g__c9--lg {
        float: left
    }
}

@media (min-width: 1601px) {
    .g__c9--xlg {
        -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
        width: 75%
    }

    .no-flexbox .g__c9--xlg {
        float: left
    }
}

@media (max-width: 600px) {
    .g__c10--sm {
        -ms-flex-preferred-size: 83.3333333333%;
        flex-basis: 83.3333333333%;
        width: 83.3333333333%
    }

    .no-flexbox .g__c10--sm {
        float: left
    }
}

@media (min-width: 601px) and (max-width: 767px) {
    .g__c10--md {
        -ms-flex-preferred-size: 83.3333333333%;
        flex-basis: 83.3333333333%;
        width: 83.3333333333%
    }

    .no-flexbox .g__c10--md {
        float: left
    }
}

@media (min-width: 768px) and (max-width: 1600px) {
    .g__c10--lg {
        -ms-flex-preferred-size: 83.3333333333%;
        flex-basis: 83.3333333333%;
        width: 83.3333333333%
    }

    .no-flexbox .g__c10--lg {
        float: left
    }
}

@media (min-width: 1601px) {
    .g__c10--xlg {
        -ms-flex-preferred-size: 83.3333333333%;
        flex-basis: 83.3333333333%;
        width: 83.3333333333%
    }

    .no-flexbox .g__c10--xlg {
        float: left
    }
}

@media (max-width: 600px) {
    .g__c11--sm {
        -ms-flex-preferred-size: 91.6666666667%;
        flex-basis: 91.6666666667%;
        width: 91.6666666667%
    }

    .no-flexbox .g__c11--sm {
        float: left
    }
}

@media (min-width: 601px) and (max-width: 767px) {
    .g__c11--md {
        -ms-flex-preferred-size: 91.6666666667%;
        flex-basis: 91.6666666667%;
        width: 91.6666666667%
    }

    .no-flexbox .g__c11--md {
        float: left
    }
}

@media (min-width: 768px) and (max-width: 1600px) {
    .g__c11--lg {
        -ms-flex-preferred-size: 91.6666666667%;
        flex-basis: 91.6666666667%;
        width: 91.6666666667%
    }

    .no-flexbox .g__c11--lg {
        float: left
    }
}

@media (min-width: 1601px) {
    .g__c11--xlg {
        -ms-flex-preferred-size: 91.6666666667%;
        flex-basis: 91.6666666667%;
        width: 91.6666666667%
    }

    .no-flexbox .g__c11--xlg {
        float: left
    }
}

@media (max-width: 600px) {
    .g__c12--sm {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        width: 100%
    }

    .no-flexbox .g__c12--sm {
        float: left
    }
}

@media (min-width: 601px) and (max-width: 767px) {
    .g__c12--md {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        width: 100%
    }

    .no-flexbox .g__c12--md {
        float: left
    }
}

@media (min-width: 768px) and (max-width: 1600px) {
    .g__c12--lg {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        width: 100%
    }

    .no-flexbox .g__c12--lg {
        float: left
    }
}

@media (min-width: 1601px) {
    .g__c12--xlg {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        width: 100%
    }

    .no-flexbox .g__c12--xlg {
        float: left
    }
}

.g__space + .g__space {
    margin-top: 16px
}

.g__space + .g__space:empty {
    margin-top: 0
}

.g__space-lite + .g__space-lite {
    margin-top: 15px
}

.u__hidden {
    display: none !important
}

@media (max-width: 600px) {
    .u__hidden--sm {
        display: none !important
    }
}

@media (min-width: 601px) and (max-width: 767px) {
    .u__hidden--md {
        display: none !important
    }
}

@media (min-width: 768px) and (max-width: 1600px) {
    .u__hidden--lg {
        display: none !important
    }
}

@media (min-width: 1601px) {
    .u__hidden--xlg {
        display: none !important
    }
}

.u__cf:before, .u__cf:after {
    content: ' ';
    display: table
}

.u__cf:after {
    clear: both
}

@media (max-width: 600px) {
    .u__cf--sm:before, .u__cf--sm:after {
        content: ' ';
        display: table
    }

    .u__cf--sm:after {
        clear: both
    }
}

@media (min-width: 601px) and (max-width: 767px) {
    .u__cf--md:before, .u__cf--md:after {
        content: ' ';
        display: table
    }

    .u__cf--md:after {
        clear: both
    }
}

@media (min-width: 768px) and (max-width: 1600px) {
    .u__cf--lg:before, .u__cf--lg:after {
        content: ' ';
        display: table
    }

    .u__cf--lg:after {
        clear: both
    }
}

@media (min-width: 1601px) {
    .u__cf--xlg:before, .u__cf--xlg:after {
        content: ' ';
        display: table
    }

    .u__cf--xlg:after {
        clear: both
    }
}

.u__left {
    float: left
}

@media (max-width: 600px) {
    .u__left--sm {
        float: left
    }
}

@media (min-width: 601px) and (max-width: 767px) {
    .u__left--md {
        float: left
    }
}

@media (min-width: 768px) and (max-width: 1600px) {
    .u__left--lg {
        float: left
    }
}

@media (min-width: 1601px) {
    .u__left--xlg {
        float: left
    }
}

.u__right {
    float: right
}

@media (max-width: 600px) {
    .u__right--sm {
        float: right
    }
}

@media (min-width: 601px) and (max-width: 767px) {
    .u__right--md {
        float: right
    }
}

@media (min-width: 768px) and (max-width: 1600px) {
    .u__right--lg {
        float: right
    }
}

@media (min-width: 1601px) {
    .u__right--xlg {
        float: right
    }
}

.u__reset {
    margin: 0;
    padding: 0;
    list-style: none
}

.u__block {
    display: block
}

.u__inline-block {
    display: inline-block
}

.u__flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.u__flex-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.u__last-item {
    margin-bottom: 0
}

.u__relative {
    position: relative
}

.u__full-width {
    width: 100%
}

.sp__1 {
    display: block;
    height: 10px
}

.sp__2 {
    display: block;
    height: 20px
}

.sp__3 {
    display: block;
    height: 30px
}

.sp__4 {
    display: block;
    height: 40px
}

.sp__5 {
    display: block;
    height: 50px
}

.sp__6 {
    display: block;
    height: 60px
}

.sp__7 {
    display: block;
    height: 70px
}

.sp__8 {
    display: block;
    height: 80px
}

.sp__9 {
    display: block;
    height: 90px
}

.sp__10 {
    display: block;
    height: 100px
}

.sp__11 {
    display: block;
    height: 110px
}

.sp__12 {
    display: block;
    height: 120px
}

.sp__13 {
    display: block;
    height: 130px
}

.sp__14 {
    display: block;
    height: 140px
}

.sp__15 {
    display: block;
    height: 150px
}

.sp__16 {
    display: block;
    height: 160px
}

.sp__17 {
    display: block;
    height: 170px
}

.sp__18 {
    display: block;
    height: 180px
}

.sp__19 {
    display: block;
    height: 190px
}

.sp__20 {
    display: block;
    height: 200px
}

@media (max-width: 600px) {
    .sp__1--sm {
        display: block;
        height: 10px
    }
}

@media (min-width: 601px) and (max-width: 767px) {
    .sp__1--md {
        display: block;
        height: 10px
    }
}

@media (min-width: 768px) and (max-width: 1600px) {
    .sp__1--lg {
        display: block;
        height: 10px
    }
}

@media (min-width: 1601px) {
    .sp__1--xlg {
        display: block;
        height: 10px
    }
}

@media (max-width: 600px) {
    .sp__2--sm {
        display: block;
        height: 20px
    }
}

@media (min-width: 601px) and (max-width: 767px) {
    .sp__2--md {
        display: block;
        height: 20px
    }
}

@media (min-width: 768px) and (max-width: 1600px) {
    .sp__2--lg {
        display: block;
        height: 20px
    }
}

@media (min-width: 1601px) {
    .sp__2--xlg {
        display: block;
        height: 20px
    }
}

@media (max-width: 600px) {
    .sp__3--sm {
        display: block;
        height: 30px
    }
}

@media (min-width: 601px) and (max-width: 767px) {
    .sp__3--md {
        display: block;
        height: 30px
    }
}

@media (min-width: 768px) and (max-width: 1600px) {
    .sp__3--lg {
        display: block;
        height: 30px
    }
}

@media (min-width: 1601px) {
    .sp__3--xlg {
        display: block;
        height: 30px
    }
}

@media (max-width: 600px) {
    .sp__4--sm {
        display: block;
        height: 40px
    }
}

@media (min-width: 601px) and (max-width: 767px) {
    .sp__4--md {
        display: block;
        height: 40px
    }
}

@media (min-width: 768px) and (max-width: 1600px) {
    .sp__4--lg {
        display: block;
        height: 40px
    }
}

@media (min-width: 1601px) {
    .sp__4--xlg {
        display: block;
        height: 40px
    }
}

@media (max-width: 600px) {
    .sp__5--sm {
        display: block;
        height: 50px
    }
}

@media (min-width: 601px) and (max-width: 767px) {
    .sp__5--md {
        display: block;
        height: 50px
    }
}

@media (min-width: 768px) and (max-width: 1600px) {
    .sp__5--lg {
        display: block;
        height: 50px
    }
}

@media (min-width: 1601px) {
    .sp__5--xlg {
        display: block;
        height: 50px
    }
}

@media (max-width: 600px) {
    .sp__6--sm {
        display: block;
        height: 60px
    }
}

@media (min-width: 601px) and (max-width: 767px) {
    .sp__6--md {
        display: block;
        height: 60px
    }
}

@media (min-width: 768px) and (max-width: 1600px) {
    .sp__6--lg {
        display: block;
        height: 60px
    }
}

@media (min-width: 1601px) {
    .sp__6--xlg {
        display: block;
        height: 60px
    }
}

@media (max-width: 600px) {
    .sp__7--sm {
        display: block;
        height: 70px
    }
}

@media (min-width: 601px) and (max-width: 767px) {
    .sp__7--md {
        display: block;
        height: 70px
    }
}

@media (min-width: 768px) and (max-width: 1600px) {
    .sp__7--lg {
        display: block;
        height: 70px
    }
}

@media (min-width: 1601px) {
    .sp__7--xlg {
        display: block;
        height: 70px
    }
}

@media (max-width: 600px) {
    .sp__8--sm {
        display: block;
        height: 80px
    }
}

@media (min-width: 601px) and (max-width: 767px) {
    .sp__8--md {
        display: block;
        height: 80px
    }
}

@media (min-width: 768px) and (max-width: 1600px) {
    .sp__8--lg {
        display: block;
        height: 80px
    }
}

@media (min-width: 1601px) {
    .sp__8--xlg {
        display: block;
        height: 80px
    }
}

@media (max-width: 600px) {
    .sp__9--sm {
        display: block;
        height: 90px
    }
}

@media (min-width: 601px) and (max-width: 767px) {
    .sp__9--md {
        display: block;
        height: 90px
    }
}

@media (min-width: 768px) and (max-width: 1600px) {
    .sp__9--lg {
        display: block;
        height: 90px
    }
}

@media (min-width: 1601px) {
    .sp__9--xlg {
        display: block;
        height: 90px
    }
}

@media (max-width: 600px) {
    .sp__10--sm {
        display: block;
        height: 100px
    }
}

@media (min-width: 601px) and (max-width: 767px) {
    .sp__10--md {
        display: block;
        height: 100px
    }
}

@media (min-width: 768px) and (max-width: 1600px) {
    .sp__10--lg {
        display: block;
        height: 100px
    }
}

@media (min-width: 1601px) {
    .sp__10--xlg {
        display: block;
        height: 100px
    }
}

@media (max-width: 600px) {
    .sp__11--sm {
        display: block;
        height: 110px
    }
}

@media (min-width: 601px) and (max-width: 767px) {
    .sp__11--md {
        display: block;
        height: 110px
    }
}

@media (min-width: 768px) and (max-width: 1600px) {
    .sp__11--lg {
        display: block;
        height: 110px
    }
}

@media (min-width: 1601px) {
    .sp__11--xlg {
        display: block;
        height: 110px
    }
}

@media (max-width: 600px) {
    .sp__12--sm {
        display: block;
        height: 120px
    }
}

@media (min-width: 601px) and (max-width: 767px) {
    .sp__12--md {
        display: block;
        height: 120px
    }
}

@media (min-width: 768px) and (max-width: 1600px) {
    .sp__12--lg {
        display: block;
        height: 120px
    }
}

@media (min-width: 1601px) {
    .sp__12--xlg {
        display: block;
        height: 120px
    }
}

@media (max-width: 600px) {
    .sp__13--sm {
        display: block;
        height: 130px
    }
}

@media (min-width: 601px) and (max-width: 767px) {
    .sp__13--md {
        display: block;
        height: 130px
    }
}

@media (min-width: 768px) and (max-width: 1600px) {
    .sp__13--lg {
        display: block;
        height: 130px
    }
}

@media (min-width: 1601px) {
    .sp__13--xlg {
        display: block;
        height: 130px
    }
}

@media (max-width: 600px) {
    .sp__14--sm {
        display: block;
        height: 140px
    }
}

@media (min-width: 601px) and (max-width: 767px) {
    .sp__14--md {
        display: block;
        height: 140px
    }
}

@media (min-width: 768px) and (max-width: 1600px) {
    .sp__14--lg {
        display: block;
        height: 140px
    }
}

@media (min-width: 1601px) {
    .sp__14--xlg {
        display: block;
        height: 140px
    }
}

@media (max-width: 600px) {
    .sp__15--sm {
        display: block;
        height: 150px
    }
}

@media (min-width: 601px) and (max-width: 767px) {
    .sp__15--md {
        display: block;
        height: 150px
    }
}

@media (min-width: 768px) and (max-width: 1600px) {
    .sp__15--lg {
        display: block;
        height: 150px
    }
}

@media (min-width: 1601px) {
    .sp__15--xlg {
        display: block;
        height: 150px
    }
}

@media (max-width: 600px) {
    .sp__16--sm {
        display: block;
        height: 160px
    }
}

@media (min-width: 601px) and (max-width: 767px) {
    .sp__16--md {
        display: block;
        height: 160px
    }
}

@media (min-width: 768px) and (max-width: 1600px) {
    .sp__16--lg {
        display: block;
        height: 160px
    }
}

@media (min-width: 1601px) {
    .sp__16--xlg {
        display: block;
        height: 160px
    }
}

@media (max-width: 600px) {
    .sp__17--sm {
        display: block;
        height: 170px
    }
}

@media (min-width: 601px) and (max-width: 767px) {
    .sp__17--md {
        display: block;
        height: 170px
    }
}

@media (min-width: 768px) and (max-width: 1600px) {
    .sp__17--lg {
        display: block;
        height: 170px
    }
}

@media (min-width: 1601px) {
    .sp__17--xlg {
        display: block;
        height: 170px
    }
}

@media (max-width: 600px) {
    .sp__18--sm {
        display: block;
        height: 180px
    }
}

@media (min-width: 601px) and (max-width: 767px) {
    .sp__18--md {
        display: block;
        height: 180px
    }
}

@media (min-width: 768px) and (max-width: 1600px) {
    .sp__18--lg {
        display: block;
        height: 180px
    }
}

@media (min-width: 1601px) {
    .sp__18--xlg {
        display: block;
        height: 180px
    }
}

@media (max-width: 600px) {
    .sp__19--sm {
        display: block;
        height: 190px
    }
}

@media (min-width: 601px) and (max-width: 767px) {
    .sp__19--md {
        display: block;
        height: 190px
    }
}

@media (min-width: 768px) and (max-width: 1600px) {
    .sp__19--lg {
        display: block;
        height: 190px
    }
}

@media (min-width: 1601px) {
    .sp__19--xlg {
        display: block;
        height: 190px
    }
}

@media (max-width: 600px) {
    .sp__20--sm {
        display: block;
        height: 200px
    }
}

@media (min-width: 601px) and (max-width: 767px) {
    .sp__20--md {
        display: block;
        height: 200px
    }
}

@media (min-width: 768px) and (max-width: 1600px) {
    .sp__20--lg {
        display: block;
        height: 200px
    }
}

@media (min-width: 1601px) {
    .sp__20--xlg {
        display: block;
        height: 200px
    }
}

.section {
    padding-top: 26px
}

.section__centered {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.section__bg {
    padding-top: 0;
    padding-bottom: 26px;
    position: relative
}

.section__bg > * {
    position: relative;
    z-index: 1
}

.section__bg:before {
    content: ' ';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #ebeef1;
    border-radius: 4px;
    -webkit-transition: background .3s;
    -o-transition: background .3s;
    transition: background .3s;
    z-index: 0
}

.intercom-align-right {
    text-align: right !important
}

.intercom-align-center {
    text-align: center !important
}

.intercom-align-left {
    text-align: left !important
}

.intercom-align-left .intercom-h2b-button {
    margin-left: 0 !important
}

.right-to-left .intercom-align-right, [dir="rtl"] .intercom-align-right {
    text-align: left !important
}

.right-to-left .intercom-align-left, [dir="rtl"] .intercom-align-left {
    text-align: right !important
}

[dir="rtl"] .intercom-align-left, .right-to-left .intercom-align-left {
    text-align: right !important
}

[dir="rtl"] li, .right-to-left li {
    text-align: right !important;
    direction: rtl
}

[dir="rtl"] .intercom-align-left img, .right-to-left .intercom-align-left img, [dir="rtl"] .intercom-align-left .intercom-h2b-button, .right-to-left .intercom-align-left .intercom-h2b-button {
    margin-left: 0 !important
}

.intercom-attachment, .intercom-attachments, .intercom-attachments td, .intercom-attachments th, .intercom-attachments tr, .intercom-attachments tbody, .intercom-attachments .icon, .intercom-attachments .icon img {
    border: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    padding: 0 !important;
    margin: 0 !important
}

.intercom-attachments {
    margin: 10px 0 !important
}

.intercom-attachments .icon, .intercom-attachments .icon img {
    width: 16px !important;
    height: 16px !important
}

.intercom-attachments .icon {
    padding-right: 5px !important
}

.intercom-attachment {
    display: inline-block !important;
    margin-bottom: 5px !important
}

.intercom-force-break {
    overflow-wrap: break-word;
    word-wrap: break-word
}

.link__arrow {
    position: relative;
    font-size: 14px;
    text-decoration: none;
    display: inline-block;
    color: #8f919d;
    margin: 0 7px 7px 0;
    padding-right: 16px;
    top: -6px
}

.link__arrow a {
    color: #4f5e6b;
    text-decoration: none
}

.link__arrow a:hover {
    color: #242a30
}

.link__arrow:before {
    content: ' ';
    position: absolute;
    display: block;
    top: 50%;
    margin-top: -5px;
    right: 0;
    width: 7px;
    height: 12px;
    font-size: 36px;
    color: #acb8c2;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAYCAYAAADKx8xXAAAAqUlEQVR4AbXUNRLCABSEYbTC3a6BU8ftBlyA+/dscHYii+TN/OUXzyvFEx1PdbRFzZI4MaqiAwqQidoqXN3QPQt1FLhAPmEbdRU8Q14C7it4moAdNFDwOAG7aKTgEXIT8FjBgwTsoamC+8hJwDMF95BN2EcLBXeQRXhVDJQvNeHh2MrD4dfhqK+DPwBG498/OfkjF34rRv1vfuReIauDl5WlLitejy3VnAHtSsjA994UIwAAAABJRU5ErkJggg==") no-repeat;
    background-size: 100%
}

.link__arrow:last-child:before {
    display: none
}

.link__arrow.o__rtl {
    margin: 0 0 7px 7px;
    padding-right: 0;
    padding-left: 16px
}

.link__arrow.o__rtl:before {
    right: auto;
    left: 0;
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    transform: scaleX(-1)
}

.header {
    color: white;
    padding: 35px 0;
    margin-bottom: 0px;
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    position: relative;
    z-index: 10;
    background-size: cover;
    -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    background-position: 50%
}

.header:before, .header:after {
    content: ' ';
    display: table
}

.header:after {
    clear: both
}

.header__meta_wrapper {
    margin-bottom: 15px;
    line-height: 1
}

.header__logo {
    font-size: 16px;
    text-decoration: none;
    font-weight: 600;
    position: relative;
    z-index: 2
}

.header__logo img {
    max-width: 360px;
    max-height: 35px;
    vertical-align: baseline;
    -webkit-backface-visibility: hidden;
    -ms-transform: translateZ(0);
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
}

.header__logo a {
    display: inline-block;
    text-decoration: none
}

@media only screen and (max-width: 600px) {
    .header__logo {
        margin-top: 20px
    }
}

.header__headline {
    font-size: 28px;
    font-weight: 300;
    margin: 0 0 27px;
    color: inherit
}

.animation__header-lite .header, .header__lite .header {
    padding: 25px 0
}

.animation__header-lite .header__form.header__form, .header__lite .header__form.header__form {
    width: 100%
}

.animation__header-lite .search__input, .header__lite .search__input {
    padding-top: 16px;
    padding-bottom: 16px
}

.animation__header-lite .header__logo, .header__lite .header__logo {
    width: 200px;
    margin-bottom: 0 !important
}

.animation__header-lite .header__name, .header__lite .header__name {
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%)
}

@media (max-width: 600px) {
    .animation__header-lite .header__form.header__form, .header__lite .header__form.header__form {
        width: 100%
    }

    .animation__header-lite .header__logo, .header__lite .header__logo {
        margin-top: 30px
    }
}

.header__lite .header__logo {
    margin: 0
}

.header__lite .header__form.header__form {
    margin-left: auto;
    float: right
}

.header__lite .header__container.o__rtl .header__form.header__form {
    float: left
}

@media (max-width: 600px) {
    .header__lite .header__logo {
        margin: 30px 0
    }

    .header__lite .header__form.header__form {
        float: none;
        margin-top: 20px
    }

    .header__lite .header__container {
        display: block
    }
}

@media (max-width: 600px) {
    .header__search-input {
        font-size: 16px;
        padding: 18px 65px 18px 18px
    }
}

.header__links {
    width: auto;
    font-weight: 600
}

.header__links a:hover {
    opacity: 0.8
}

.header__links svg {
    width: 13px;
    height: 13px;
    margin: 0 8px;
    vertical-align: middle
}

.header__links span {
    font-size: 14px;
    display: inline-block;
    vertical-align: middle
}

@media only screen and (max-width: 600px) {
    .header__links span {
        font-size: 13px
    }

    .header__links svg {
        width: 11px;
        height: 11px
    }
}

@media (max-width: 170px) {
    .header__links {
        display: none
    }
}

@media only screen and (max-width: 600px) {
    .header__container.o__rtl .header__links {
        position: absolute;
        top: 10px;
        left: 20px
    }
}

@media only screen and (max-width: 600px) {
    .header__container.o__ltr .header__links {
        position: absolute;
        top: 10px;
        right: 20px
    }
}

.header.o__mobile_embedded {
    padding-top: 20px;
    padding-bottom: 20px
}

.header.o__mobile_embedded .header__site_name, .header.o__mobile_embedded .header__home__url, .header.o__mobile_embedded .header__headline {
    display: none
}

.header.o__mobile_embedded .header__meta_wrapper {
    min-height: 16px
}

.footer {
    margin-top: 35px;
    padding: 40px 0;
    text-align: left;
    font-size: 14px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    background: #ffffff;
    color: #a3abb5
}

.footer a {
    color: #909aa5;
    text-decoration: none
}

.footer__logo {
    height: 50px;
    line-height: 30px;
    text-align: center;
    vertical-align: middle
}

.footer__logo img {
    max-height: 32px;
    -webkit-filter: grayscale(100%) contrast(80%);
    filter: grayscale(100%) contrast(80%)
}

.footer__logo span {
    line-height: 1.2;
    font-size: 18px
}

.footer__advert {
    text-align: center;
    font-size: 14px
}

.footer__advert img {
    height: 14px;
    vertical-align: middle
}

.footer__advert a {
    padding-left: 5px;
    color: #a8b6c2;
    vertical-align: middle
}

.footer__links {
    margin-top: 14px;
    margin-bottom: 28px
}

.footer__link-list {
    padding: 0;
    text-align: center
}

.footer__link {
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
    list-style-type: none
}

.footer__links__social svg {
    width: 16px;
    height: 16px;
    fill: currentColor
}

.paper {
    background-color: white;
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 30px;
    position: relative;
    z-index: 3;
    text-decoration: none;
    overflow: hidden;
    width: 100%;
    display: block;
    outline: none;
    -webkit-box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
    -webkit-transition: border .15s linear, background-color .15s linear, opacity .15s linear, -webkit-transform .15s linear, -webkit-box-shadow .15s linear;
    transition: border .15s linear, background-color .15s linear, opacity .15s linear, -webkit-transform .15s linear, -webkit-box-shadow .15s linear;
    -o-transition: border .15s linear, transform .15s linear, background-color .15s linear, box-shadow .15s linear, opacity .15s linear;
    transition: border .15s linear, transform .15s linear, background-color .15s linear, box-shadow .15s linear, opacity .15s linear;
    transition: border .15s linear, transform .15s linear, background-color .15s linear, box-shadow .15s linear, opacity .15s linear, -webkit-transform .15s linear, -webkit-box-shadow .15s linear
}

.paper .c__primary {
    transition: -webkit-filter .15s
}

.paper__article-preview {
    -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid #d4dadf;
    margin-left: 30px;
    margin-right: 30px;
    width: calc(100% - 60px);
    opacity: 1;
    -webkit-transition: opacity .3s;
    -o-transition: opacity .3s;
    transition: opacity .3s
}

.paper__article-preview:not(:last-child) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

.paper__article-preview .paper__article-preview .paper__preview {
    min-height: 0
}

.paper__article-preview:hover {
    border: 1px solid #c3cdd5;
    -webkit-box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.08)
}

.search__results-content .paper__article-preview {
    margin: 0;
    width: 100%
}

.paper__article-preview + .paper__article-preview {
    margin-top: -2px;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

a.paper:hover {
    border: 1px solid rgba(136, 149, 162, 0.2);
    background-color: #fcfcfc
}

a.paper:hover .c__primary {
    -webkit-filter: brightness(80%);
    filter: brightness(80%)
}

.paper__large {
    padding: 60px
}

.paper__preview {
    margin: 5px 0 11px;
    text-decoration: none;
    display: block;
    max-width: 630px;
    line-height: 1.4
}

.article__preview .paper__preview {
    min-height: 0
}

@media (max-width: 1023px) {
    .g__c {
        width: 100%
    }

    .paper {
        padding: 30px 20px
    }

    .paper__large {
        padding: 30px
    }
}

@media (max-width: 600px) {
    .paper__large {
        padding: 25px 20px
    }
}

.avatar {
    font-size: 13px;
    color: #8f919d;
    margin-top: 4px
}

.avatar:before, .avatar:after {
    content: ' ';
    display: table
}

.avatar:after {
    clear: both
}

.avatar__image {
    width: 32px;
    height: 32px;
    vertical-align: bottom;
    border-radius: 50%;
    -webkit-box-shadow: 0 0 0 2px white;
    box-shadow: 0 0 0 2px white;
    position: relative;
    z-index: 4
}

.avatar__fallback {
    display: inline-block;
    text-align: center;
    line-height: 32px;
    font-size: 18px;
    font-weight: bold;
    color: white
}

.avatar__photo {
    margin-right: 10px;
    float: left
}

.avatar__photo.o__rtl {
    margin-right: auto;
    margin-left: 10px;
    float: right
}

.avatar__image:nth-child(n + 2) {
    margin-left: -8px
}

.avatar__image:nth-child(2) {
    z-index: 3;
    opacity: .8
}

.avatar__image:nth-child(3) {
    z-index: 2;
    opacity: .6
}

.avatar__image:nth-child(4) {
    z-index: 1;
    opacity: .4
}

.avatars__images.o__rtl .avatar__image:nth-child(n + 2) {
    margin-left: 0;
    margin-right: -8px
}

.avatar__info, .avatar__photo {
    line-height: 1.4
}

@media (max-width: 1023px) {
    .avatar__image:nth-child(n + 2) {
        display: none
    }
}

@media (max-width: 400px) {
    .avatar__info {
        float: right;
        width: calc(100% - 42px)
    }
}

.article {
    font-size: 17px;
    line-height: 1.53
}

.article__meta {
    margin-bottom: 30px
}

.article__desc {
    font-size: 20px;
    line-height: 1.35;
    margin-bottom: 17px;
    color: #8F919D
}

article {
    font-size: 17px;
    color: #565867;
    white-space: pre-wrap;
    white-space: break-spaces
}

article p {
    margin-top: 0
}

article p.no-margin {
    margin-bottom: 0;
    min-height: 1.53em
}

article h1 {
    font-size: 32px;
    margin: 2em 0 1.2em;
    line-height: 1.24
}

article h2 {
    font-size: 22px;
    margin: 0 0 17px;
    line-height: 1.24
}

article h3 {
    font-size: 18px;
    margin: 0 0 15px;
    font-weight: 600;
    line-height: 1.24
}

article h4 {
    font-size: 18px;
    margin: 0 0 15px;
    font-weight: 600;
    line-height: 1.24
}

article h5 {
    font-size: 14px;
    margin: 1.2em 0 .8em;
    line-height: 1.24
}

article h6 {
    font-size: 13px;
    margin: 1em 0 .5em;
    line-height: 1.24
}

article a {
    text-decoration: underline
}

article a:hover {
    text-decoration: none
}

article hr {
    display: block;
    height: 3px;
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.05);
    border: none;
    margin: 1em 0
}

article ul, article ol {
    padding-left: 16px
}

article li {
    margin-left: 15px;
    line-height: 1.53
}

article li > .intercom-container:first-child {
    display: inline-block;
    vertical-align: top
}

article li > pre:first-child {
    display: inline-block;
    width: 100%;
    vertical-align: top
}

article li > hr:first-child {
    display: inline-block;
    width: 100%;
    vertical-align: middle
}

article li hr {
    margin: 0
}

article li img, article li .intercom-h2b-video, article li a.intercom-h2b-button {
    margin-bottom: 0
}

article ul > li, article ul > li ul > li ul > li ul > li, article ul > li ul > li ul > li ul > li ul > li ul > li ul > li {
    list-style-type: disc
}

article ul > li ul > li, article ul > li ul > li ul > li ul > li ul > li, article ul > li ul > li ul > li ul > li ul > li ul > li ul > li ul > li {
    list-style-type: circle
}

article ul > li ul > li ul > li, article ul > li ul > li ul > li ul > li ul > li ul > li, article ul > li ul > li ul > li ul > li ul > li ul > li ul > li ul > li ul > li {
    list-style-type: square
}

article ol > li, article ol > li ol > li ol > li ol > li, article ol > li ol > li ol > li ol > li ol > li ol > li ol > li {
    list-style-type: decimal
}

article ol > li ol > li, article ol > li ol > li ol > li ol > li ol > li, article ol > li ol > li ol > li ol > li ol > li ol > li ol > li ol > li {
    list-style-type: lower-latin
}

article ol > li ol > li ol > li, article ol > li ol > li ol > li ol > li ol > li ol > li, article ol > li ol > li ol > li ol > li ol > li ol > li ol > li ol > li ol > li {
    list-style-type: lower-roman
}

article img, article video, article iframe {
    margin-top: 0;
    margin-bottom: 34px
}

article a:hover {
    text-decoration: none
}

article a.intercom-h2b-button {
    display: inline-block;
    font-weight: bold;
    color: #fff;
    background-color: #de2cde;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 8px 40px;
    font-size: 17px;
    line-height: 1.4;
    text-decoration: none;
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
    margin: 0 0 17px
}

article .intercom-h2b-video {
    position: relative;
    display: block;
    margin: 0 0 40px;
    height: 0;
    overflow: hidden;
    padding: 0 0 56.25%
}

article .intercom-h2b-video iframe, article .intercom-h2b-video embed, article .intercom-h2b-video object, article .intercom-h2b-video video {
    position: absolute;
    margin: 0;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0
}

article table {
    display: block;
    overflow-x: auto;
    margin: 36px -20px 24px
}

article table a.intercom-h2b-button {
    padding: 8px
}

article tbody {
    width: 640px;
    display: inline-table;
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    margin: 0 20px 12px;
    vertical-align: top
}

article th, article td {
    padding: 8px;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    border-top: 1px solid rgba(0, 0, 0, 0.2)
}

article tr:first-child > td {
    border-top: none
}

article td:first-child, article th:first-child {
    border-left: none
}

article > .intercom-container:first-child > :first-child, article > :first-child {
    margin-top: 0 !important
}

article > .intercom-container:last-child > :last-child, article > :last-child {
    margin-bottom: 0 !important
}

@media (max-width: 600px) {
    .article__desc {
        font-size: 17px
    }

    article {
        font-size: 15px
    }

    article h1 {
        font-size: 28px
    }

    article h2 {
        font-size: 22px
    }

    article h3 {
        font-size: 18px
    }

    article h4 {
        font-size: 18px
    }

    article h5 {
        font-size: 16px
    }

    article h6 {
        font-size: 14px
    }

    article .intercom-container, article .intercom-h2b-video {
        margin: 1.5em 0
    }

    article .intercom-interblocks-table-container {
        position: relative
    }

    article table::before {
        content: "";
        position: absolute;
        width: 20px;
        top: 0;
        left: -20px;
        bottom: 10px;
        background: -webkit-gradient(linear, left top, right top, from(white), color-stop(20%, white), to(rgba(255, 255, 255, 0)));
        background: -o-linear-gradient(left, white, white 20%, rgba(255, 255, 255, 0));
        background: linear-gradient(to right, white, white 20%, rgba(255, 255, 255, 0))
    }

    article table::after {
        content: "";
        position: absolute;
        width: 20px;
        top: 0;
        right: -20px;
        bottom: 10px;
        background: -webkit-gradient(linear, right top, left top, from(white), color-stop(20%, white), to(rgba(255, 255, 255, 0)));
        background: -o-linear-gradient(right, white, white 20%, rgba(255, 255, 255, 0));
        background: linear-gradient(to left, white, white 20%, rgba(255, 255, 255, 0))
    }

    article li .intercom-container, article li .intercom-h2b-video {
        margin: 0
    }
}

.search {
    position: relative
}

.search__input {
    width: 100%;
    padding: 20px 32px 21px 59px;
    background: rgba(255, 255, 255, 0.2);
    border: none;
    outline: none;
    color: rgba(255, 255, 255, 0.7);
    font-size: 18px;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03);
    -webkit-transition: background .4s, -webkit-box-shadow .2s;
    transition: background .4s, -webkit-box-shadow .2s;
    -o-transition: background .4s, box-shadow .2s;
    transition: background .4s, box-shadow .2s;
    transition: background .4s, box-shadow .2s, -webkit-box-shadow .2s
}

.search__input::-webkit-input-placeholder {
    color: inherit
}

.search__input:-moz-placeholder {
    color: inherit
}

.search__input::-moz-placeholder {
    color: inherit
}

.search__input:-ms-input-placeholder {
    color: inherit
}

.search__input:hover {
    background: rgba(255, 255, 255, 0.27);
    -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.03);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.03)
}

.search__input:active, .search__input:focus {
    -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.14);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.14);
    background: white;
    color: #3a3c4c
}

.search__input:active::-webkit-input-placeholder, .search__input:focus::-webkit-input-placeholder {
    color: #9fa0a8
}

.search__input:active:-moz-placeholder, .search__input:focus:-moz-placeholder {
    color: #9fa0a8
}

.search__input:active::-moz-placeholder, .search__input:focus::-moz-placeholder {
    color: #9fa0a8
}

.search__input:active:-ms-input-placeholder, .search__input:focus:-ms-input-placeholder {
    color: #9fa0a8
}

.search__input.o__rtl {
    padding: 20px 59px 21px 32px
}

.search__submit {
    width: 22px;
    height: 22px;
    border: none;
    background: transparent;
    position: absolute;
    top: 50%;
    left: 19px;
    margin-top: -10px;
    outline: none;
    cursor: pointer;
    -webkit-transition: -webkit-transform .1s linear;
    transition: -webkit-transform .1s linear;
    -o-transition: transform .1s linear;
    transition: transform .1s linear;
    transition: transform .1s linear, -webkit-transform .1s linear
}

.animation__header-lite .search__submit, .page__header-lite .search__submit, .header__lite .search__submit {
    -webkit-transform: scale(0.85);
    -ms-transform: scale(0.85);
    transform: scale(0.85)
}

.search__submit:before {
    content: ' ';
    border: 2px solid white;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px
}

.search__submit:after {
    content: ' ';
    border-top: 2px solid white;
    position: absolute;
    bottom: 8px;
    right: 8px;
    width: 12px;
    height: 1px;
    -webkit-transition: width .1s linear;
    -o-transition: width .1s linear;
    transition: width .1s linear;
    -webkit-transform-origin: 100% 50%;
    -ms-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    transform: rotate(-135deg)
}

.search__submit.o__rtl {
    left: auto;
    right: 19px
}

.search__headline {
    padding: 16px 0 11px 0;
    margin: -5px 0;
    color: #3a3c4c;
    font-weight: 600;
    letter-spacing: .02em;
    font-size: 18px
}

.search__clear-text__icon {
    position: absolute;
    right: 0;
    top: 50%;
    width: 32px;
    height: 32px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-top: -14px
}

.interface-icon {
    width: 25px;
    height: 25px;
    display: inline-block;
    vertical-align: middle;
    text-align: center
}

.interface-icon path {
    fill: #9fa0a8 !important;
    fill-opacity: 0.01
}

.search__input:focus + .search_icons .search__submit, .search__input:active + .search_icons .search__submit {
    pointer-events: none
}

.search__input:focus + .search_icons .search__submit:before, .search__input:focus + .search_icons .search__submit:after, .search__input:active + .search_icons .search__submit:before, .search__input:active + .search_icons .search__submit:after {
    border-color: #9fa0a8
}

.search__input:focus + .search_icons .icon__visible path, .search__input:active + .search_icons .icon__visible path {
    fill-opacity: 1
}

.search__loading {
    opacity: 0;
    -webkit-transition: opacity 0.2s ease-out;
    -o-transition: opacity 0.2s ease-out;
    transition: opacity 0.2s ease-out
}

.search__results {
    opacity: 1;
    -webkit-animation-name: fadeInOpacity;
    animation-name: fadeInOpacity;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s
}

@-webkit-keyframes fadeInOpacity {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

.collection {
    position: relative;
    padding-left: 150px
}

.collection__photo {
    position: absolute;
    left: 0;
    top: 0;
    width: 120px;
    height: 100%;
    -webkit-transition: all .3s linear;
    -o-transition: all .3s linear;
    transition: all .3s linear
}

.collection__photo svg {
    position: relative;
    width: 48px;
    height: 48px;
    -webkit-transition: stroke .1s linear;
    -o-transition: stroke .1s linear;
    transition: stroke .1s linear;
    top: 50%;
    left: 50%;
    margin: -24px 0 0 -24px
}

.collection__photo [fill^="#"] {
    -webkit-transition: fill .1s linear;
    -o-transition: fill .1s linear;
    transition: fill .1s linear
}

.collection.o__rtl {
    padding-left: 0;
    padding-right: 150px
}

.collection.o__rtl .collection__photo {
    left: auto;
    right: 0
}

a.paper:hover .collection__photo {
    border-color: #d0d7df
}

.collection__meta .avatar__image {
    -webkit-box-shadow: 0 0 0 2px #ebeef1;
    box-shadow: 0 0 0 2px #ebeef1
}

@media (max-width: 1023px) {
    .collection {
        padding-left: 80px;
        min-height: 80px
    }

    .collection.o__rtl {
        padding-left: 0;
        padding-right: 80px
    }

    .collection__photo {
        width: 60px;
        height: 60px
    }

    .collection__photo svg {
        width: 30px;
        max-height: 30px;
        margin: -15px 0 0 -15px
    }
}

@media (max-width: 400px) {
    .collection {
        padding-left: 0;
        min-height: 0
    }

    .collection.o__rtl {
        padding-right: 0;
        text-align: right
    }

    .collection__photo {
        width: 30px;
        height: 30px;
        position: static;
        margin-bottom: 20px
    }

    .collection__photo svg {
        width: 30px;
        max-height: 30px;
        left: auto;
        top: auto;
        margin: 0
    }

    .collection.o__rtl .collection__photo {
        display: inline-block
    }
}

.collection__headline, .paper__stack.animation__clicked {
    background: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid transparent;
    border-radius: 0
}

.collection__headline:before, .collection__headline:after, .paper__stack.animation__clicked:before, .paper__stack.animation__clicked:after {
    display: none
}

.collection__headline .paper, .paper__stack.animation__clicked .paper {
    background: none;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none
}

.section__headline {
    padding: 16px 30px 11px;
    margin: -5px 0;
    color: #3a3c4c;
    font-weight: 600;
    letter-spacing: .02em;
    font-size: 18px
}

@media (max-width: 1023px) {
    .section__headline {
        padding-left: 20px;
        padding-right: 20px;
        margin-left: 10px;
        margin-right: 10px
    }
}

.collection__header + .section__headline .section__content {
    border-top: none
}

pre {
    word-wrap: break-word;
    word-break: break-word;
    padding: 16px;
    margin: 1em 0;
    line-height: 1.65;
    color: #333;
    background: #f8f8f8;
    overflow: auto
}

code {
    word-break: break-word;
    color: #333;
    background: #f8f8f8;
    -webkit-text-size-adjust: none;
    padding-top: 0.2em;
    padding-bottom: 0.2em
}

pre, code {
    font-family: monospace;
    font-size: 13px
}

.hljs {
    display: block;
    padding: 1.5em;
    color: #333;
    background: #f8f8f8;
    -webkit-text-size-adjust: none
}

.hljs code {
    padding-top: 0;
    padding-bottom: 0;
    background: none
}

.hljs code::before, .hljs code::after {
    letter-spacing: normal;
    content: normal
}

code.hljs {
    display: inline-block;
    padding-top: 0;
    padding-bottom: 0;
    background: none
}

code.hljs::before, code.hljs::after {
    letter-spacing: normal;
    content: normal
}

p + code.hljs {
    display: block
}

.hljs-comment, .diff .hljs-header {
    color: #998;
    font-style: italic
}

.hljs-keyword, .css .rule .hljs-keyword, .hljs-winutils, .nginx .hljs-title, .hljs-subst, .hljs-request, .hljs-status {
    color: #333;
    font-weight: bold
}

.hljs-number, .hljs-hexcolor, .ruby .hljs-constant {
    color: #008080
}

.hljs-string, .hljs-tag .hljs-value, .hljs-doctag, .tex .hljs-formula {
    color: #d14
}

.hljs-title, .hljs-id, .scss .hljs-preprocessor {
    color: #900;
    font-weight: bold
}

.hljs-list .hljs-keyword, .hljs-subst {
    font-weight: normal
}

.hljs-class .hljs-title, .hljs-type, .vhdl .hljs-literal, .tex .hljs-command {
    color: #458;
    font-weight: bold
}

.hljs-tag, .hljs-tag .hljs-title, .hljs-rule .hljs-property, .django .hljs-tag .hljs-keyword {
    color: #000080;
    font-weight: normal
}

.hljs-attribute, .hljs-variable, .lisp .hljs-body, .hljs-name {
    color: #008080
}

.hljs-regexp {
    color: #009926
}

.hljs-symbol, .ruby .hljs-symbol .hljs-string, .lisp .hljs-keyword, .clojure .hljs-keyword, .scheme .hljs-keyword, .tex .hljs-special, .hljs-prompt {
    color: #990073
}

.hljs-built_in {
    color: #0086b3
}

.hljs-preprocessor, .hljs-pragma, .hljs-pi, .hljs-doctype, .hljs-shebang, .hljs-cdata {
    color: #999;
    font-weight: bold
}

.hljs-deletion {
    background: #fdd
}

.hljs-addition {
    background: #dfd
}

.diff .hljs-change {
    background: #0086b3
}

.hljs-chunk {
    color: #aaa
}

.social__facebook {
    display: inline-block;
    vertical-align: bottom
}

.social__facebook svg {
    height: 16px
}

.social__facebook * {
    fill: #96a4b4
}

.social__twitter {
    display: inline-block;
    vertical-align: bottom;
    position: relative;
    top: 1px
}

.social__twitter svg {
    height: 16px
}

.social__twitter * {
    fill: #96a4b4
}

.social__linkedin {
    display: inline-block;
    vertical-align: bottom;
    margin: 0 5px 0 3px
}

.social__linkedin svg {
    height: 16px
}

.social__linkedin * {
    fill: #96a4b4
}

.social__facebook:hover *, .social__twitter:hover *, .social__linkedin:hover * {
    fill: #788a9f
}

.error__404 {
    text-align: center
}

.error__title {
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: .1em;
    color: #e04e50;
    font-weight: 600
}

.error__headline {
    font-size: 32px
}

.notification__error {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #9e4747;
    color: white;
    text-align: center;
    padding: 5px 20px;
    font-size: 13px;
    z-index: 100
}

.breadcrumb {
    position: relative;
    z-index: 1
}

.body__headerless .breadcrumb {
    display: none
}

.body__headerless .section {
    margin-right: auto;
    margin-left: auto
}

.body__headerless .container {
    padding: 0
}

.body__headerless .paper {
    margin-bottom: 35px
}

.body__messenger__headerless {
    overflow: hidden;
    background: white
}

.body__messenger__headerless .error__cta {
    display: none
}

.body__messenger__headerless .section__article {
    padding: 0
}

.body__messenger__headerless .section__article .paper {
    border: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none
}

.body__messenger__headerless .content.educate_content {
    padding-bottom: 24px
}

.body__messenger__headerless .paper__large {
    -webkit-transition: padding 0.2s ease-out;
    -o-transition: padding 0.2s ease-out;
    transition: padding 0.2s ease-out;
    padding: 0 24px;
    padding-top: 0px
}

.body__messenger__headerless article, .body__messenger__headerless .c__darker, .body__messenger__headerless h1, .body__messenger__headerless h2, .body__messenger__headerless h3, .body__messenger__headerless h4, .body__messenger__headerless h5, .body__messenger__headerless h6 {
    color: #000
}

.body__messenger__headerless h2 {
    font-size: 20px
}

.body__messenger__headerless h3 {
    font-size: 16px;
    font-weight: 600
}

.body__messenger__headerless article {
    font-size: 14px;
    line-height: 150%
}

.body__messenger__headerless article p {
    min-height: 21px
}

.body__messenger__headerless article p.no-margin {
    margin-bottom: 0
}

.body__messenger__headerless article h1, .body__messenger__headerless article h2, .body__messenger__headerless article h3, .body__messenger__headerless article h4, .body__messenger__headerless article h5, .body__messenger__headerless article h6 {
    margin-top: 0;
    margin-bottom: 12px
}

.body__messenger__headerless article pre, .body__messenger__headerless article ul, .body__messenger__headerless article ol, .body__messenger__headerless article p.intercom-align-left {
    margin-top: 0;
    margin-bottom: 24px
}

.body__messenger__headerless article ul, .body__messenger__headerless article ol, .body__messenger__headerless article li, .body__messenger__headerless article h1, .body__messenger__headerless article h2, .body__messenger__headerless article h3, .body__messenger__headerless article h4, .body__messenger__headerless article h5, .body__messenger__headerless article h6 {
    line-height: 150%
}

.body__messenger__headerless article img, .body__messenger__headerless article video, .body__messenger__headerless article iframe, .body__messenger__headerless article a.intercom-h2b-button {
    margin-top: 0;
    margin-bottom: 0
}

.body__messenger__headerless article > .intercom-container, .body__messenger__headerless article > .intercom-h2b-video {
    margin-top: 0;
    margin-bottom: 24px
}

.body__messenger__headerless article table, .body__messenger__headerless article hr {
    margin-top: 24px;
    margin-bottom: 24px
}

.body__messenger__headerless article tbody {
    width: 320px;
    margin-bottom: 0
}

.body__messenger__headerless article p.no-margin:empty:last-child {
    display: none
}

.body__messenger__headerless .article__meta {
    margin-bottom: 24px
}

.body__messenger__headerless .article__desc {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0
}

.body__messenger__headerless .article__desc, .body__messenger__headerless .avatar {
    color: #737376
}

.body__messenger__headerless .avatar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 24px
}

.body__messenger__headerless .avatar__image {
    width: 36px;
    height: 36px
}

.body__messenger__headerless .avatar__fallback {
    line-height: 36px
}

.body__messenger__headerless .avatar__info {
    font-size: 14px;
    line-height: 21px
}

.body__messenger__headerless .avatar__info span.c__darker {
    color: #737376
}

.body__messenger__headerless .paper {
    margin-bottom: 0 !important
}

@media (min-width: 500px) {
    .body__messenger__headerless article {
        font-size: 16px;
        line-height: 150%
    }

    .body__messenger__headerless article > .intercom-container, .body__messenger__headerless article > .intercom-h2b-video {
        margin-left: -48px;
        margin-right: -48px
    }

    .body__messenger__headerless article > .intercom-container img, .body__messenger__headerless article > .intercom-h2b-video img {
        margin-left: auto;
        margin-right: auto;
        display: block
    }

    .body__messenger__headerless article .intercom-container .intercom-h2b-button:first-of-type, .body__messenger__headerless article .intercom-container.intercom-align-left .intercom-h2b-button:first-of-type {
        margin-left: 48px !important
    }

    .body__messenger__headerless article tbody {
        width: 540px
    }

    .body__messenger__headerless article p {
        min-height: 24px
    }

    .body__messenger__headerless .paper__large {
        padding: 0px 72px 0px 72px
    }

    .body__messenger__headerless .article__desc {
        font-size: 18px;
        line-height: 27px
    }

    .body__messenger__headerless h2 {
        font-size: 22px
    }

    .body__messenger__headerless h3 {
        font-size: 18px
    }

    .body__messenger__headerless .content.educate_content {
        padding-bottom: 48px
    }
}

.body__mobile__headerless {
    background: white
}

.body__mobile__headerless .error__cta {
    display: none
}

.body__mobile__headerless .section__article {
    padding: 0
}

.body__mobile__headerless .section__article .paper {
    border: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none
}

.body__mobile__headerless h1 {
    font: -apple-system-title0
}

.body__mobile__headerless h2 {
    font: -apple-system-title2
}

.body__mobile__headerless h3, .body__mobile__headerless a.intercom-h2b-button {
    font: -apple-system-headline
}

.body__mobile__headerless h4, .body__mobile__headerless article, .body__mobile__headerless .article__desc {
    font: -apple-system-subheadline
}

.body__mobile__headerless h5, .body__mobile__headerless h6, .body__mobile__headerless .avatar__info {
    font: -apple-system-caption1
}

.body__sheet {
    width: 100vw
}

.body__sheet.body__headerless .paper {
    margin-bottom: 0
}

.body__sheet .container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch
}

.body__sheet .section__search_results {
    padding: 15px
}

.body__sheet .section__search_results .avatar {
    display: none
}

.body__sheet .section__search_results .search__headline {
    padding: 0;
    margin-bottom: 16px
}

.body__sheet .section__search_results .paper__preview {
    margin-bottom: 0
}

.body__sheet .section__search_results .search-results__row {
    margin-top: 0
}

.body__sheet .section__search_results .search-results__row:nth-child(2) .paper {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px
}

.body__sheet .section__search_results .search-results__row .paper {
    border-radius: 0;
    border-bottom: 0
}

.body__sheet .section__search_results .search-results__row:last-child .paper {
    border-bottom: 1px solid #d4dadf;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px
}

.body__sheet .section__article {
    padding: 0
}

.body__sheet .section__article .paper {
    border: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none
}

.intercom-reaction-picker {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    padding: 12px;
    text-align: center;
    color: #777;
    background-color: #f0f3f5;
    margin: 60px -44px -44px -44px;
    border-radius: 8px
}

@media (max-width: 1023px) {
    .intercom-reaction-picker {
        margin: 30px -16px -16px -16px
    }
}

@media (max-width: 600px) {
    .intercom-reaction-picker {
        margin: 30px -8px -12px -8px;
        border-radius: 8px
    }
}

.intercom-reaction-picker.intercom-reaction-picker-with-prompt {
    height: 74px
}

.intercom-reaction-picker .intercom-reaction-prompt {
    padding-top: 9px;
    padding-bottom: 4px;
    text-align: center
}

.intercom-reaction-picker .intercom-reaction {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: none;
    width: 50px;
    height: 100%;
    display: inline-block;
    text-align: center;
    font-size: 32px;
    -webkit-transition: -webkit-transform 0.16s cubic-bezier(0.65, 0.61, 0.18, 1.8) 0.02s, -webkit-filter 0.32s linear;
    transition: -webkit-transform 0.16s cubic-bezier(0.65, 0.61, 0.18, 1.8) 0.02s, -webkit-filter 0.32s linear;
    -o-transition: transform 0.16s cubic-bezier(0.65, 0.61, 0.18, 1.8) 0.02s, filter 0.32s linear;
    transition: transform 0.16s cubic-bezier(0.65, 0.61, 0.18, 1.8) 0.02s, filter 0.32s linear;
    transition: transform 0.16s cubic-bezier(0.65, 0.61, 0.18, 1.8) 0.02s, filter 0.32s linear, -webkit-transform 0.16s cubic-bezier(0.65, 0.61, 0.18, 1.8) 0.02s, -webkit-filter 0.32s linear;
    cursor: pointer;
    -webkit-transform-origin: 50% 60%;
    -ms-transform-origin: 50% 60%;
    transform-origin: 50% 60%
}

.intercom-reaction-picker .intercom-reaction span {
    cursor: pointer;
    line-height: 55px
}

.intercom-reaction-picker .intercom-reaction:hover, .intercom-reaction-picker .intercom-reaction:focus {
    -webkit-transform: scale(1.32);
    -ms-transform: scale(1.32);
    transform: scale(1.32);
    -webkit-transition: -webkit-transform .04s;
    transition: -webkit-transform .04s;
    -o-transition: transform .04s;
    transition: transform .04s;
    transition: transform .04s, -webkit-transform .04s
}

.intercom-reaction-picker .intercom-reaction:active {
    -webkit-transform: scale(1.4);
    -ms-transform: scale(1.4);
    transform: scale(1.4);
    -webkit-transition: -webkit-transform .04s;
    transition: -webkit-transform .04s;
    -o-transition: transform .04s;
    transition: transform .04s;
    transition: transform .04s, -webkit-transform .04s
}

.intercom-reaction-picker-reaction-selected .intercom-reaction {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%)
}

.intercom-reaction-picker-reaction-selected .intercom-reaction-selected {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
    -webkit-transform: scale(1.32);
    -ms-transform: scale(1.32);
    transform: scale(1.32)
}

.intercom-reaction-picker-reaction-selected .intercom-reaction-selected:hover {
    -webkit-transform: scale(1.32);
    -ms-transform: scale(1.32);
    transform: scale(1.32)
}

.intercom-reaction-picker-reaction-selected .intercom-reaction-selected:active {
    -webkit-transform: scale(1.32);
    -ms-transform: scale(1.32);
    transform: scale(1.32)
}

.intermoji-default-class {
    background: url(./assets/emoji-spritemap-32.png);
    position: relative;
    top: 2px;
    margin: 1px
}

.locale_picker__container {
    background-color: #fff;
    text-align: center;
    padding: 8px 0 20px 0
}

.locale-picker {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    height: 36px;
    color: #737373;
    padding: 0 32px 2px 32px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-family: proxima-nova;
    margin: 0 8px;
    border: 1px solid rgba(115, 115, 115, 0.2);
    border-radius: 100px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url("./assets/glob-icon.svg"), url("./assets/down-arrow.svg");
    background-repeat: no-repeat, no-repeat;
    background-position: 10px center, right 12px center;
    background-size: 14px, 16px;
    -webkit-transition: border-color .25s, outline .25s;
    -o-transition: border-color .25s, outline .25s;
    transition: border-color .25s, outline .25s;
    cursor: pointer
}

.locale-picker:hover {
    border-color: rgba(115, 115, 115, 0.4);
    opacity: 0.8
}

.locale-picker:focus {
    outline: none
}

.locale-picker option {
    color: black
}

.locale_picker__container .locale-picker:focus {
    -webkit-box-shadow: 0 0 1px 3px #f0f3f5;
    box-shadow: 0 0 1px 3px #f0f3f5;
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    outline: none
}

.locale_picker__container .locale-picker:hover {
    opacity: 1
}

#locale-picker-for-width-calculation {
    display: none
}

.header__container .locale-picker {
    color: white;
    border: none;
    background-color: transparent;
    background-image: url("./assets/globe-icon-white.svg"), url("./assets/down-arrow-white.svg");
    padding: 0 20px 2px 20px;
    background-position: left center, right center;
    position: relative;
    top: 1px
}

.header__container.o__rtl .locale-picker {
    margin-right: 20px
}

.header__container.o__ltr .locale-picker {
    margin-left: 20px
}

.animation__mouse-down {
    -webkit-transform: scale(0.99);
    -ms-transform: scale(0.99);
    transform: scale(0.99);
    -webkit-transition: none;
    -o-transition: none;
    transition: none
}

.animation__mouse-up {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: none;
    -o-transition: none;
    transition: none
}

.animation__fade-generic {
    -webkit-transition: opacity .3s;
    -o-transition: opacity .3s;
    transition: opacity .3s
}

.animation__slide-in .animation__fade-generic {
    opacity: 0
}

.animation__fade-collection {
    -webkit-transition: opacity .3s;
    -o-transition: opacity .3s;
    transition: opacity .3s;
    -webkit-transition-delay: .5s;
    -o-transition-delay: .5s;
    transition-delay: .5s
}

.animation__article-in .animation__fade-generic, .animation__article-in .article__wrap > * {
    opacity: 0;
    -webkit-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s
}

.animation__article-in .paper__article-preview {
    opacity: 0;
    -webkit-transition: opacity .1s;
    -o-transition: opacity .1s;
    transition: opacity .1s;
    -webkit-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s
}

.animation__article-in .paper__article-preview.animation__clicked {
    opacity: 1
}

.animation__article-in .paper__article-preview.animation__clicked .article__preview {
    opacity: 0;
    -webkit-transition: opacity .1s;
    -o-transition: opacity .1s;
    transition: opacity .1s
}

.collection__headline-lite .collection__photo, .animation__article-in .collection__photo {
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
    border-right-color: transparent;
    -webkit-transform: scale(0.6) translate(20px, -100px);
    -ms-transform: scale(0.6) translate(20px, -100px);
    transform: scale(0.6) translate(20px, -100px)
}

.collection__headline-lite .collection__name, .animation__article-in .collection__name {
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
    -webkit-transform-origin: left center;
    -ms-transform-origin: left center;
    transform-origin: left center;
    -webkit-transform: scale(0.8) translate(-60px, -13px);
    -ms-transform: scale(0.8) translate(-60px, -13px);
    transform: scale(0.8) translate(-60px, -13px)
}

.collection__headline-lite .collection__headline, .animation__article-in .collection__headline {
    border-bottom-color: transparent
}

.collection__headline-lite .collection .animation__fade-generic, .animation__article-in .collection .animation__fade-generic {
    opacity: 0
}

.animation__article-out .collection__photo {
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
    border-right-color: #dbe0e4;
    -webkit-transform: scale(1) translate(0, 0);
    -ms-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0)
}

.animation__article-out .collection__name {
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
    -webkit-transform-origin: left center;
    -ms-transform-origin: left center;
    transform-origin: left center;
    -webkit-transform: scale(1) translate(0, 0);
    -ms-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0)
}

.animation__article-out .collection .animation__fade-generic {
    opacity: 1
}

.animation__article-out .article__wrap-react {
    opacity: 0
}

.animation__article-out .section__bg-active:before {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1)
}

.animation__article-in .section__bg:before, .section__bg-active:before {
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
    -webkit-transform: scaleX(0.9);
    -ms-transform: scaleX(0.9);
    transform: scaleX(0.9);
    height: 180px
}

.animation__section-bg-static .section__bg:before {
    -webkit-transition: none;
    -o-transition: none;
    transition: none
}

.article__wrap-react {
    -webkit-transform-origin: top center;
    -ms-transform-origin: top center;
    transform-origin: top center;
    margin: -125px 30px 0;
    width: calc(100% - 60px)
}

.collection__headline-lite a.paper:hover {
    border: 1px solid transparent;
    background: none
}

.collection__headline-lite a.paper:hover .collection__photo {
    border-color: transparent;
    background: none
}

.collection__photo svg {
    stroke: #818a97
}

.collection__photo [fill^="#"] {
    fill: #818a97
}

.avatar__image-extra, .header {
    /*background-color: #1f8ded*/
    background-image: url(./assets/images/hero-bg/hero-5.jpg);
}

.c__primary, .article a {
    color: #1f8ded
}